import Steel_Standards from '../data/Steel_Standards.json';
import Concrete_Standards from '../data/Concrete_Standards.json';
import Timber_Standards from '../data/Timber_Standards.json';
import StainlessSteel_Standards from '../data/StainlessSteel_Standards.json';
import Annex from '../data/Annex.json';

export const getStandardsAndNationalAnnex = subcategory => {
  switch (subcategory) {
    case 'steel':
      return {
        standards: Steel_Standards,
        nationalAnnexes: Annex,
      };
    case 'concrete':
      return {
        standards: Concrete_Standards,
        nationalAnnexes: Annex,
      };
    case 'timber':
      return {
        standards: Timber_Standards,
        nationalAnnexes: Annex,
      };
    default: //To change
      return {
        standards: StainlessSteel_Standards,
        nationalAnnexes: Annex,
      };
  }
};

import { Box, HStack, Spacer, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { translate } from '../services/languageService';
import SubcategoryAvatar from './SubcategoryAvatar';

export default function SubcategoryListItem({ subcategory, lang, size }) {
  return (
    <Box h="full" w="full">
      <NavLink to={`${subcategory.path}`}>
        <HStack w="full" h="full" spacing="4" p="4">
          <SubcategoryAvatar size={size} subcategory={subcategory} />
          <Text fontSize={size}>{translate(subcategory.name, lang)}</Text>
          <Spacer />
          <Text fontWeight="semibold" fontSize={size} textAlign="end">
            {subcategory.count}
          </Text>
        </HStack>
      </NavLink>
    </Box>
  );
}

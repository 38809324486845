import { FaFilter } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { VStack } from '@chakra-ui/react';
import { getTagsOfASubcategory } from '../services/subcategoryService';
import AssetList from './AssetList';
import AssetListFilterbar from './AssetListFilterbar';
import SubcategoryPageTitle from './SubcategoryPageTitle';
import { REQUEST_STATUS } from '../hooks/useRestRequest';
import NotFoundPage from './pages/NotFoundPage';
import LoadingPage from './pages/LoadingPage';
import { useSubcategoryPageContent } from '../hooks/useSubcategoryPageContent';

export default function SubcategoryPageContent({
  bg,
  category,
  categoryPath,
  filter,
  lang,
  subcategory,
  size,
}) {
  const {
    items,
    setItems,
    currentDbItems,
    setCurrentDbItems,
    requestStatus,
    userSettings,
  } = useSubcategoryPageContent(category.id, subcategory.id, categoryPath);

  const subcategoryFilter = {
    get: () => {
      return filter.getFilter(subcategory.id);
    },
    updateSearch: search => {
      filter.updateFilterSearch(subcategory.id, search);
    },
    updateTags: tags => {
      filter.updateFilterTags(subcategory.id, tags);
    },
    updateFavorite: isFavorite => {
      filter.updateFilterIsFavorite(subcategory.id, isFavorite);
    },
    clear: () => {
      filter.clearFilter(subcategory.id);
    },
  };

  const toggleFilterbar = () => {
    filter.setShow(!filter.show);
  };

  const FilterIcon = subcategoryFilter.get().isEmpty ? FiFilter : FaFilter;

  const allTags = getTagsOfASubcategory(items);

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage />;
  }

  if (requestStatus === REQUEST_STATUS.FAILURE) {
    return <NotFoundPage />;
  }

  return (
    <VStack
      bg={bg}
      w={'950px'}
      maxW={'1100px'}
      boxShadow={'lg'}
      justify={{ base: 'center' }}
      align={{ base: 'center', md: 'center' }}
      display="flex"
      direction={['column', 'row']}
      borderWidth="1px"
      rounded={'2xl'}
      p="4"
      spacing="4"
    >
      <SubcategoryPageTitle
        category={category}
        subcategory={subcategory}
        icon={<FilterIcon />}
        lang={lang}
        size={size}
        toggleFilterBar={toggleFilterbar}
      />
      <AssetListFilterbar
        filter={subcategoryFilter}
        filterIcon={<FilterIcon />}
        show={filter.show}
        onToggle={toggleFilterbar}
        allTags={allTags}
      />
      <AssetList
        filter={subcategoryFilter}
        items={items}
        setItems={setItems}
        currentDbItems={currentDbItems}
        setCurrentDbItems={setCurrentDbItems}
        categoryId={category.id}
        subcategoryId={subcategory.id}
        settingsId={userSettings?.data.id}
      />
    </VStack>
  );
}

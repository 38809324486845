import { Box, HStack, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useSteelAdvancedProperties } from '../hooks/useSteelAdvancedProperties';
import InputNumber from './InputNumber';
import FFunction from './FFunction';
import { getFieldColor } from '../services/fieldColorService';

export default function SteelAdvancedProperties({
  allAdvancedProperties,
  standard,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  resizeSubtitles,
  isOfficialVersion,
  t,
}) {
  const {
    safetyCoefficients,
    setSafetyCoefficients,
    strengthFunctions,
    setStrengthFunctions,
  } = useSteelAdvancedProperties(allAdvancedProperties);

  const addStrength = kvp => {
    let positionPoint = 100 / (kvp.value.points.length - 1) ?? 1;
    let currentPositionPoint = 0;
    let borderColor = getFieldColor(kvp?.standard, kvp?.value, standard);
    return (
      <Box mb="3" key={kvp.key}>
        <FFunction
          property={kvp}
          currentPositionPoint={currentPositionPoint}
          positionPoint={positionPoint}
          styleProperties={styleProperties}
          resizeElements={resizeElements}
          t={t}
          colorStyle={colorStyle}
          inputWidth={inputWidth}
          setStrengthFunctions={setStrengthFunctions}
          borderLeftColor={borderColor}
          isOfficialVersion={isOfficialVersion}
        />
      </Box>
    );
  };

  const addSafetyCoefficient = kvp => {
    const label = kvp.key.split('_')[1].toUpperCase();
    const inputNumberProps = {
      isReadOnly: isOfficialVersion,
      value: kvp.value,
      step: 0.1,
      onChange: val => {
        setSafetyCoefficients(prevState => {
          let newState = Object.assign([], prevState);
          let modified = newState.find(x => x.key === kvp.key);
          if (modified.value === parseFloat(val)) {
            return prevState;
          }
          modified.value = val;
          return newState;
        });
      },
      borderLeftColor: getFieldColor(kvp?.standard, kvp?.value, standard),
      ...colorStyle,
      ...inputWidth,
    };
    return (
      <HStack mb="1" key={kvp.key} pr="5">
        <FormLabel {...styleProperties}>
          <Text as="sup" fontSize={resizeElements}>
            {t('advancedProperties.gamma')}
          </Text>
          <Text as="sub">{label}</Text>
        </FormLabel>
        <InputNumber
          styleProperties={styleProperties}
          resizeElements={resizeElements}
          props={inputNumberProps}
        />
      </HStack>
    );
  };

  return (
    <FormControl alignContent="center" pl="5" mb="5">
      <Box mb="5">
        <Text fontSize={resizeSubtitles} fontWeight="hairline" as="u">
          {t('steelAdvancedProperties.strength')}
        </Text>
      </Box>
      {strengthFunctions?.map(addStrength)}
      <Box mb="5">
        <Text fontSize={resizeSubtitles} fontWeight="hairline" as="u">
          {t('steelAdvancedProperties.safetyCoefficient')}
        </Text>
      </Box>
      {safetyCoefficients?.map(addSafetyCoefficient)}
    </FormControl>
  );
}

import {
  Box,
  VStack,
  Text,
  HStack,
  Spacer,
  Divider,
  Switch,
  FormControl,
  FormLabel,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { translate } from '../services/languageService';
import SubcategoryAvatar from './SubcategoryAvatar';
import CategoryAvatar from './CategoryAvatar';
import { useTranslation } from 'react-i18next';
import { useCategorySettingsContent } from '../hooks/useCategorySettingsContent';

export default function CategorySettingsContent({
  category,
  lang,
  categorySettings,
  bg,
  categorySize,
  subcategorySize,
  settingsId,
}) {
  const { t } = useTranslation('translation');
  const toast = useToast();
  const { settings, updateCategorySettings, updateSubcategoryHide } =
    useCategorySettingsContent(
      categorySettings,
      category,
      lang,
      settingsId,
      toast,
      t
    );

  return (
    <Box w="100%" display="flex">
      <VStack
        bg={bg}
        w={'100%'}
        maxW={'1100px'}
        boxShadow={'lg'}
        justify={{ base: 'center' }}
        align={{ base: 'center', md: 'center' }}
        display="flex"
        direction={['column', 'row']}
        borderWidth="1px"
        rounded={'2xl'}
        p="4"
        spacing="4"
      >
        <HStack w={'full'} spacing="6">
          <CategoryAvatar category={category} size={categorySize} />
          <Text fontWeight="semibold" fontSize={categorySize}>
            {translate(category.name, lang)}
          </Text>
        </HStack>
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="hideEmptySubcategories"
            mb="0"
            fontSize={subcategorySize}
          >
            {t('categorySettings.hideEmptySubcategories')}
          </FormLabel>
          <Switch
            id="hideEmptySubcategories"
            isChecked={settings?.hideEmptySubcategories}
            onChange={() =>
              updateCategorySettings(
                settings?.hideEmptySubcategories ? 'unhide' : 'hide'
              )
            }
          />
        </FormControl>
        <Divider />
        {settings?.subcategories.map(subcategory => {
          return (
            <Box h="full" w="full" key={subcategory.id}>
              <HStack w="full" h="full" spacing="1" p="1">
                <Tooltip
                  label={
                    subcategory.hide === true
                      ? t('categorySettings.show')
                      : t('categorySettings.hide')
                  }
                  placement="bottom"
                >
                  <Box>
                    <Switch
                      id="hideSubcategory"
                      mr="2"
                      size="sm"
                      isChecked={subcategory.hide}
                      onChange={() =>
                        updateSubcategoryHide(
                          subcategory.id,
                          subcategory.hide ? 'unhide' : 'hide'
                        )
                      }
                    />
                  </Box>
                </Tooltip>
                <SubcategoryAvatar
                  size={subcategorySize}
                  subcategory={subcategory}
                  mr="2"
                />
                <Text fontSize={subcategorySize}>
                  {translate(subcategory.name, lang)}
                </Text>
                <Spacer />
                <Text
                  fontWeight="semibold"
                  fontSize={subcategorySize}
                  textAlign="end"
                >
                  {subcategory.count}
                </Text>
              </HStack>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
}

import {
  Box,
  VStack,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';
import { REQUEST_STATUS } from '../../hooks/useRestRequest';
import { useTranslation } from 'react-i18next';
import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import CategorySettingsContent from '../CategorySettingsContent';
import { useCategorySettings } from '../../hooks/useCategorySettings';

export default function CategorySettingsPage({ minH }) {
  const { categories, requestStatus } = useCategoriesContext();
  const { settings, requestStatus: settingsRequestStatus } =
    useCategorySettings();
  const { i18n } = useTranslation('translation');
  const lang = i18n.language;
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const resizeCategoryAvatar = useBreakpointValue({ base: 'sm', md: 'md' });
  const resizeSubcategoryAvatar = useBreakpointValue({ base: 'xs', md: 'sm' });

  const getCategorySettings = categoryId => {
    return settings.data.attributes.categories.find(c => c.id === categoryId);
  };

  if (
    settingsRequestStatus === REQUEST_STATUS.LOADING ||
    requestStatus === REQUEST_STATUS.LOADING
  ) {
    return <LoadingPage minH={minH} />;
  }

  if (categories === undefined || settings === undefined) {
    return <NotFoundPage minH={minH} />;
  }

  return (
    <Box
      display="flex"
      alignItems="baseline"
      minH={minH}
      p="5"
      justifyContent="center"
    >
      <VStack
        bg={categoriesBg}
        w={'950px'}
        maxW={'1100px'}
        justify={{ base: 'center' }}
        align={{ base: 'center', md: 'center' }}
        display="flex"
        direction={['column', 'row']}
        p="4"
        rounded={'2xl'}
        spacing="4"
        justifyContent="center"
      >
        {categories.map(category => {
          return (
            <CategorySettingsContent
              key={category.id}
              category={category}
              lang={lang}
              categorySettings={getCategorySettings(category.id)}
              bg={categoriesBg}
              categorySize={resizeCategoryAvatar}
              subcategorySize={resizeSubcategoryAvatar}
              settingsId={settings.data.id}
            />
          );
        })}
      </VStack>
    </Box>
  );
}

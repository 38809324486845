import { Box, useBreakpointValue } from '@chakra-ui/react';
import { getStandardsAndNationalAnnex } from '../services/materialsJsonDataService';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS, useRestRequest } from '../hooks/useRestRequest';
import NotFoundPage from '../components/pages/NotFoundPage';
import LoadingPage from '../components/pages/LoadingPage';
import { useMaterialDetail } from '../hooks/useMaterialDetail';
import { useIdentityContext } from '../contexts/IdentityContext';
import { MaterialHistoricDataUrl } from '../services/urlService';
import MaterialDetailContent from './MaterialDetailContent';

export default function MaterialDetail({
  category,
  assetId,
  subcategory,
  lang,
}) {
  const { authHeaders } = useIdentityContext();
  const { data: assetHistoricData, requestStatus } = useRestRequest(
    MaterialHistoricDataUrl(assetId),
    authHeaders
  );
  const lastVersion = assetHistoricData?.data.attributes.versions.reduce(
    function (result, currentValue) {
      if (currentValue.version > result) {
        result = currentValue.version;
      }
      return result;
    },
    1
  );
  const {
    version,
    setVersion,
    standard,
    setStandard,
    nationalAnnex,
    setNationalAnnex,
  } = useMaterialDetail(lastVersion);

  const { t } = useTranslation('translation');
  const styleProperties = {
    fontSize: 'sm',
    fontWeight: 'light',
    w: '25%',
  };
  const inputWidth = {
    w: '70%',
  };
  const resizeElements = useBreakpointValue({ base: 'xs', md: 'sm' });
  const colorStyle = {
    borderLeftRadius: '2',
    borderLeftWidth: '0.33rem',
  };
  const selectStyle = {
    borderLeftWidth: '0.33rem',
    _hover: {
      bg: 'none',
    },
    _focus: {
      bg: 'none',
    },
  };
  const { standards, nationalAnnexes } = getStandardsAndNationalAnnex(
    subcategory.path
  );

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage />;
  }

  if (requestStatus !== REQUEST_STATUS.SUCCESS) {
    return <NotFoundPage />;
  }

  const updateNationalAnnex = (selectedStandard, currentData) => {
    const currentStandard = currentData.propertyGroups.find(
      k => k.name.default === selectedStandard
    );
    if (!currentStandard || !currentStandard.fallbackId) {
      setNationalAnnex(null);
      return;
    }
    if (
      currentData.propertyGroups.find(x => x.fallbackId === currentStandard.id)
    ) {
      if (!nationalAnnex) {
        setNationalAnnex('default');
      }
      return;
    }
    setNationalAnnex(null);
  };

  return (
    <Box w="full" pr="3" pl="3">
      <MaterialDetailContent
        assetId={assetId}
        version={version}
        subcategory={subcategory}
        standard={standard}
        nationalAnnex={nationalAnnex}
        updateNationalAnnex={updateNationalAnnex}
        styleProperties={styleProperties}
        colorStyle={colorStyle}
        resizeElements={resizeElements}
        inputWidth={inputWidth}
        category={category}
        lang={lang}
        selectStyle={selectStyle}
        assetHistoricData={assetHistoricData.data}
        t={t}
        setVersion={setVersion}
        setNationalAnnex={setNationalAnnex}
        setStandard={setStandard}
        standards={standards}
        nationalAnnexes={nationalAnnexes}
      />
    </Box>
  );
}

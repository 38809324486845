import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

export default function InputNumber({
  styleProperties,
  resizeElements,
  props,
}) {
  return (
    <NumberInput {...styleProperties} {...props} size={resizeElements} min={0}>
      <NumberInputField
        _focus={{
          borderColor: props.borderLeftColor,
        }}
      />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}

import { useEffect, useState } from 'react';

export const useMaterialDetail = lastVersion => {
  const [version, setVersion] = useState(lastVersion);
  const [standard, setStandard] = useState('NotSpecified');
  const [nationalAnnex, setNationalAnnex] = useState(null);

  useEffect(() => {
    setVersion(lastVersion);
  }, [lastVersion]);

  return {
    version,
    setVersion,
    standard,
    setStandard,
    nationalAnnex,
    setNationalAnnex,
  };
};

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Select,
  TagCloseButton,
  Tag,
  TagLabel,
  ButtonGroup,
  Button,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import { translate } from '../services/languageService';
import { useCreateMaterialModal } from '../hooks/useCreateMaterialModal';
import { MaterialsUrl } from '../services/urlService';
import { useAxios } from '../hooks/useAxios';

export default function CreateMaterialModal({
  isOpen,
  onClose,
  lang,
  currentSubcategory,
  subcategories,
  t,
  assetId,
}) {
  const toast = useToast();
  const { postAxios, response } = useAxios(toast);

  const {
    name,
    setName,
    subcategory,
    setSubcategory,
    tags,
    setTags,
    tagInput,
    setTagInput,
    displayNameError,
    setDisplayNameError,
    displaySubcategoryError,
    setDisplaySubcategoryError,
    isLoading,
    setIsLoading,
  } = useCreateMaterialModal(subcategories, currentSubcategory, response);

  const isError = () => {
    if (name === '' || subcategory === '') {
      setDisplayNameError(name === '' ? true : false);
      setDisplaySubcategoryError(subcategory === '' ? true : false);
      return true;
    }
    return false;
  };

  const cleanStates = () => {
    setName('');
    setTags([]);
    if (!currentSubcategory) {
      setSubcategory('');
    }
    setTagInput('');
    setIsLoading(false);
  };

  const cleanErrors = () => {
    setDisplayNameError(false);
    setDisplaySubcategoryError(false);
  };

  const addNewMaterial = async (name, subcategoryId, tags, assetId = null) => {
    const payload = {
      data: {
        attributes: {
          name: {
            default: name,
          },
          tags: tags,
        },
        relationships: {
          subcategory: {
            data: {
              type: 'subcategory',
              id: subcategoryId,
            },
          },
          basedOn:
            assetId === null
              ? null
              : { data: { type: 'material', id: assetId } },
        },
      },
    };
    await postAxios(payload, MaterialsUrl, t('createMaterialModal.error'));
  };

  const getTags = tag => {
    return (
      <Tag mb="3" mr="1" ml="1" key={tag}>
        <TagLabel>{tag}</TagLabel>
        <TagCloseButton
          onClick={() => setTags(() => tags.filter(x => x !== tag))}
        />
      </Tag>
    );
  };

  const getSubcategories = subcategory => {
    return (
      <option value={subcategory.id} key={subcategory.id}>
        {translate(subcategory.name, lang)}
      </option>
    );
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay opacity="0.5" />
      <ModalContent>
        <ModalHeader>Modal Header</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose();
            cleanStates();
            cleanErrors();
          }}
        />
        <ModalBody>
          <Box w="100%" pr="5">
            <FormControl isRequired pb="5" isInvalid={displayNameError}>
              <FormLabel>{t('subcategoryItems.name')}</FormLabel>
              <Input
                placehilder="name"
                value={name}
                onChange={event => {
                  setName(event.target.value);
                  setDisplayNameError(event.target.value === '' ? true : false);
                }}
              />
              {displayNameError ? (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              ) : null}
            </FormControl>
            {!currentSubcategory ? (
              <FormControl
                isRequired
                pb="5"
                isInvalid={displaySubcategoryError}
              >
                <FormLabel htmlFor="country">
                  {t('subcategoryItems.subcategory')}
                </FormLabel>
                <Select
                  value={subcategory}
                  onChange={event => {
                    setSubcategory(event.target.value);
                    setDisplaySubcategoryError(
                      event.target.value === '' ? true : false
                    );
                  }}
                >
                  <option
                    style={{ display: 'none' }}
                    defaultValue=""
                    fontWeight="light"
                  >
                    {t('subcategoryItems.selectSubcategory')}
                  </option>
                  {subcategories.map(getSubcategories)}
                </Select>
                {displaySubcategoryError ? (
                  <FormErrorMessage>Subcategory is required.</FormErrorMessage>
                ) : null}
              </FormControl>
            ) : null}
            <FormControl>
              <FormLabel>{t('subcategoryItems.tags')}</FormLabel>
              {tags.map(getTags)}
              <Input
                value={tagInput}
                onChange={e => setTagInput(e.target.value)}
                onKeyPress={event => {
                  if (event.target.value.replace(/\s/g, '') === '') {
                    setTagInput('');
                    return;
                  }
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (
                      !tags.some(
                        tag =>
                          tag.toLowerCase() === event.target.value.toLowerCase()
                      )
                    ) {
                      setTags([...tags, event.target.value.trim()]);
                    }
                    setTagInput('');
                  }
                }}
              />
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup variant="outline" spacing="6">
            <Button
              isLoading={isLoading}
              loadingText={t('common.save')}
              spinnerPlacement="end"
              colorScheme="red"
              onClick={async () => {
                if (isError()) {
                  return;
                }
                setIsLoading(true);
                await addNewMaterial(name, subcategory, tags, assetId);
                cleanStates();
                onClose();
              }}
            >
              {t('common.save')}
            </Button>
            <Button
              onClick={() => {
                onClose();
                cleanStates();
                cleanErrors();
              }}
            >
              {t('common.cancel')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { useRef, useEffect } from 'react';
import { useAxios } from './useAxios';
import { SettingsUrl } from '../services/urlService';

export const useAssetList = (
  items,
  setItems,
  categoryId,
  subcategoryId,
  settingsId,
  currentDbItems,
  setCurrentDbItems,
  toast,
  CellMeasurerCache,
  t
) => {
  const { debouncedHandler, dbUpdated, setDbUpdated } = useAxios(toast);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 50,
    })
  );

  const onFavoriteToggle = async originalItem => {
    const itemUpdated = {
      ...originalItem,
      isFavorite: !originalItem.isFavorite,
    };
    const itemsUpdated = items.map(item => {
      return item.id === itemUpdated.id ? itemUpdated : item;
    });
    setDbUpdated(false);
    setItems(itemsUpdated);
    const favoriteList = itemsUpdated
      .filter(x => x.isFavorite === true)
      .map(item => item.id);
    const payload = {
      data: {
        type: 'settings',
        id: settingsId,
        attributes: {
          categories: [
            {
              id: categoryId,
              subcategories: [
                {
                  id: subcategoryId,
                  favorites: favoriteList,
                },
              ],
            },
          ],
        },
      },
    };
    await debouncedHandler(
      payload,
      SettingsUrl,
      t('subcategoryItems.favoritesError'),
      setItems,
      currentDbItems
    );
  };

  useEffect(() => {
    const handleResize = () => {
      cache.current.clearAll();
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (dbUpdated) {
      setCurrentDbItems(JSON.parse(JSON.stringify(items)));
    }
  }, [items, dbUpdated, setCurrentDbItems]);

  return { cache, onFavoriteToggle };
};

import {
  Box,
  Button,
  Icon,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMechanicalProperties } from '../hooks/useMechanicalProperties';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import InputNumber from './InputNumber';
import { getFieldColor } from '../services/fieldColorService';

export default function MechanicalProperties({
  propertyGroups,
  selectedPropertyGroup,
  standard,
  nationalAnnex,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  isOfficialVersion,
}) {
  const { t } = useTranslation('translation');
  const {
    young,
    setYoung,
    poisson,
    setPoisson,
    transverseYoung,
    setTransverseYoung,
    dilatation,
    setDilatation,
    density,
    setDensity,
    show,
    setShow,
  } = useMechanicalProperties(
    propertyGroups,
    selectedPropertyGroup,
    standard,
    nationalAnnex
  );

  const addMetric = (text, sup) => {
    return (
      <HStack spacing="0" w="5%">
        <Flex>
          <Text fontSize="xs" fontWeight="light" isTruncated>
            {text}
          </Text>
          <Text as="sup" fontSize="xx-small" fontWeight="light">
            {sup}
          </Text>
        </Flex>
      </HStack>
    );
  };

  const addProperty = (
    label,
    metric,
    state,
    setState,
    quantityMetricConversion = 1
  ) => {
    const props = {
      isReadOnly: isOfficialVersion,
      value:
        state !== undefined
          ? state?.value / quantityMetricConversion
          : undefined,
      onChange: val => {
        setState(prevState => {
          return {
            key: prevState.key,
            value: val * quantityMetricConversion,
            standard: prevState.standard,
          };
        });
      },
      borderLeftColor: getFieldColor(state?.standard, state?.value, standard),
      ...colorStyle,
      ...inputWidth,
    };
    return (
      <HStack mb="3" spacing="25px">
        <FormLabel {...styleProperties} fontSize={resizeElements}>
          {label}
        </FormLabel>
        <InputNumber
          styleProperties={styleProperties}
          resizeElements={resizeElements}
          props={props}
        />
        {metric}
      </HStack>
    );
  };

  return (
    <Box>
      <Button
        fontWeight="semibold"
        mb="3"
        justifySelf="center"
        isFullWidth
        variant="outline"
        justifyContent="flex-start"
        onClick={() => setShow(!show)}
      >
        <Flex w="100%" alignItems="center">
          {t('mechanicalProperties.mechanicalProperties')}
          <Spacer />
          <Icon as={show ? FaCaretUp : FaCaretDown} />
        </Flex>
      </Button>
      {show ? (
        <FormControl alignContent="center" pl="5" pr="5" mb="5">
          {addProperty(
            t('mechanicalProperties.youngsModulus'),
            addMetric('N/mm', '2'),
            young,
            setYoung,
            1000000
          )}
          {addProperty(
            t('mechanicalProperties.poissonRatio'),
            addMetric(' ', ' '),
            poisson,
            setPoisson
          )}
          {addProperty(
            t('mechanicalProperties.transverseYoungsModulus'),
            addMetric('N/mm', '2'),
            transverseYoung,
            setTransverseYoung,
            1000000
          )}
          {addProperty(
            t('mechanicalProperties.thermDilatation'),
            addMetric('/ºC', ''),
            dilatation,
            setDilatation
          )}
          {addProperty(
            t('mechanicalProperties.density'),
            addMetric('kg/m', '3'),
            density,
            setDensity
          )}
        </FormControl>
      ) : null}
    </Box>
  );
}

import { useIdentityContext } from '../contexts/IdentityContext';
import { useRestRequest } from './useRestRequest';
import { SettingsUrl } from '../services/urlService';

export const useCategorySettings = () => {
  const { authHeaders } = useIdentityContext();
  const { data: settings, requestStatus } = useRestRequest(
    SettingsUrl,
    authHeaders,
    true
  );
  return { settings, requestStatus };
};

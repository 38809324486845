import { useState, useEffect } from 'react';

export const useGeneralAdvancedProperties = advancedProperties => {
  const [allProperties, setAllProperties] = useState();

  useEffect(() => {
    setAllProperties(advancedProperties);
  }, [advancedProperties]);

  return {
    allProperties,
    setAllProperties,
  };
};

import { useState, useEffect } from 'react';
import { SettingsUrl } from '../services/urlService';
import { orderSubcategories } from '../services/sortingService';
import { useAxios } from './useAxios';

export const useCategorySettingsContent = (
  categorySettings,
  category,
  lang,
  settingsId,
  toast,
  t
) => {
  const [settings, setSettings] = useState();
  const [currentDbSettings, setCurrentDbSettings] = useState();
  const { patchAxios, dbUpdated, setDbUpdated, debouncedHandler } = useAxios(toast);

  const updateCategorySettings = async action => {
    const newValue = action === 'hide' ? true : false;
    setDbUpdated(false);
    const updateState = newValue => {
      setSettings(prevState => {
        let newState = { ...prevState };
        newState.hideEmptySubcategories = newValue;
        return newState;
      });
    };
    const payload = {
      data: {
        type: 'settings',
        id: settingsId,
        attributes: {
          categories: [
            {
              id: category.id,
              hideEmptySubcategories: newValue,
              subcategories: [],
            },
          ],
        },
      },
    };
    updateState(newValue);
    await patchAxios(
      payload,
      SettingsUrl,
      t('categorySettings.error'),
      setSettings,
      currentDbSettings
    );
  };

  const updateSubcategoryHide = async (subcategoryId, action) => {
    const newValue = action === 'hide' ? true : false;
    setDbUpdated(false);
    const updateState = newValue => {
      setSettings(prevState => {
        let newState = { ...prevState };
        let subcategoryToUpdate = newState.subcategories.find(
          x => x.id === subcategoryId
        );
        subcategoryToUpdate.hide = newValue;
        let currentCategory = payload.data.attributes.categories.find(
          x => (x.id = category.id)
        );
        currentCategory.subcategories = [
          ...newState.subcategories,
          subcategoryToUpdate,
        ];
        return newState;
      });
    };
    let payload = {
      data: {
        type: 'settings',
        id: settingsId,
        attributes: {
          categories: [
            {
              id: category.id,
              subcategories: [],
            },
          ],
        },
      },
    };
    updateState(newValue);
    await debouncedHandler(
      payload,
      SettingsUrl,
      t('categorySettings.error'),
      setSettings,
      currentDbSettings
    );
  };

  useEffect(() => {
    const updateState = () => {
      if (settings) {
        return;
      }
      const subcategories = orderSubcategories(
        categorySettings?.subcategories ?? [],
        category.subcategories,
        lang,
        categorySettings?.hideEmptySubcategories,
        true
      );
      const mergedCategorySettings = categorySettings
        ? {
            id: categorySettings.id,
            hideEmptySubcategories: categorySettings.hideEmptySubcategories,
            subcategories,
          }
        : {
            id: category.id,
            hideEmptySubcategories: false,
            subcategories,
          };
      setSettings(mergedCategorySettings);
      setCurrentDbSettings(JSON.parse(JSON.stringify(mergedCategorySettings)));
    };
    updateState();
  }, [categorySettings, category, lang, settings]);

  useEffect(() => {
    if (dbUpdated) {
      setCurrentDbSettings(JSON.parse(JSON.stringify(settings)));
    }
  }, [settings, dbUpdated]);

  return {
    settings,
    updateCategorySettings,
    updateSubcategoryHide,
  };
};

import { Box, useColorModeValue, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { REQUEST_STATUS } from '../../hooks/useRestRequest';
import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';
import { useTranslation } from 'react-i18next';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { CATEGORY_PATH } from '../../services/categoryPathService';
import MaterialDetail from '../MaterialDetail';
import SectionDetail from '../SectionDetail';
import RebarMeshesDetail from '../RebarMeshesDetail';
import ConnectionElementsDetail from '../ConnectionElementsDetail';

export default function DetailPage({ minH }) {
  const { i18n } = useTranslation('translation');
  const { categoryPath, subcategoryPath, assetId } = useParams();
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const lang = i18n.language;
  const { getCategory, requestStatus } = useCategoriesContext();
  const category = getCategory(categoryPath);
  const subcategory = category?.subcategories.find(
    sub => sub.path === subcategoryPath
  );

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage minH={minH} />;
  }

  if (category === undefined || subcategory === undefined) {
    return <NotFoundPage minH={minH} />;
  }

  const props = {
    category,
    subcategory,
    assetId,
    lang,
  };
  const determineDetailPageContent = categoryPath => {
    switch (categoryPath) {
      case CATEGORY_PATH.MATERIALS:
        return <MaterialDetail {...props} />;
      case CATEGORY_PATH.SECTIONS:
        return <SectionDetail {...props} />;
      case CATEGORY_PATH.REBAR_MESHES:
        return <RebarMeshesDetail {...props} />;
      case CATEGORY_PATH.CONNECTION_ELEMENTS:
        return <ConnectionElementsDetail {...props} />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <Box
      display="flex"
      alignItems="baseline"
      minH={minH}
      p="5"
      justifyContent="center"
    >
      <VStack
        bg={categoriesBg}
        w={'950px'}
        maxW={'1100px'}
        boxShadow={'lg'}
        justify={{ base: 'center' }}
        align={{ base: 'center', md: 'center' }}
        display="flex"
        direction={['column', 'row']}
        borderWidth="1px"
        rounded={'2xl'}
        p="4"
        spacing="4"
      >
        {determineDetailPageContent(category.path)}
      </VStack>
    </Box>
  );
}

import { useState, useEffect } from 'react';
import { getSteelAdvancedProperties } from '../services/materialsPropertiesService';

export const useSteelAdvancedProperties = advancedProperties => {
  const [safetyCoefficients, setSafetyCoefficients] = useState();
  const [strengthFunctions, setStrengthFunctions] = useState();

  useEffect(() => {
    const updateStates = () => {
      if (!advancedProperties) {
        return;
      }
      const { safetyCoefficient, strength } =
        getSteelAdvancedProperties(advancedProperties);
      setSafetyCoefficients(safetyCoefficient);
      setStrengthFunctions(strength);
    };
    updateStates();
  }, [advancedProperties]);

  return {
    safetyCoefficients,
    setSafetyCoefficients,
    strengthFunctions,
    setStrengthFunctions,
  };
};

import { useState, useEffect } from 'react';
import { getTimberAdvancedProperties } from '../services/materialsPropertiesService';

export const useTimberAdvancedProperties = advancedProperties => {
  const [generalProps, setGeneralProps] = useState();
  const [kmod, setKmod] = useState();
  const [kdef, setKdef] = useState();
  const [etaWAndPhi, setEtaWAndPhi] = useState();

  useEffect(() => {
    const updateStates = () => {
      if (!advancedProperties) {
        return;
      }
      const {
        generalPropsProperties,
        kmodProperties,
        kdefProperties,
        etaWAndPhiProperties,
      } = getTimberAdvancedProperties(advancedProperties);

      setGeneralProps(generalPropsProperties);
      setKmod(kmodProperties);
      setKdef(kdefProperties);
      setEtaWAndPhi(etaWAndPhiProperties);
    };

    updateStates();
  }, [advancedProperties]);

  return {
    generalProps,
    setGeneralProps,
    kmod,
    setKmod,
    kdef,
    setKdef,
    etaWAndPhi,
    setEtaWAndPhi,
  };
};

import { useEffect, useState } from 'react';
import axios from 'axios';

export const REQUEST_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const useRestRequest = (
  url,
  authHeaders,
  onlyWithAuthHeaders = false
) => {
  const [data, setData] = useState();
  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (!url) {
        return;
      }
      try {
        const config = {
          headers: authHeaders,
        };
        // console.log(`axios: get ${url}`);
        // console.log(config);
        const hasValidAuthHeaders =
          config?.headers?.DevKey && config?.headers?.DevUserId;
        if (onlyWithAuthHeaders && !hasValidAuthHeaders) {
          setRequestStatus(REQUEST_STATUS.FAILURE);
          setError('authentication headers not set');
          return;
        }
        const result = await axios.get(url, config);
        // console.log(`axios result: ${url}`);
        // console.log(result);
        setData(result.data);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      } catch (e) {
        setRequestStatus(REQUEST_STATUS.FAILURE);
        setData(undefined);
        setError(e);
        // console.log(e);
      }
    }
    fetchData();
  }, [authHeaders, onlyWithAuthHeaders, url]);

  return { data, requestStatus, error };
};

import {
  Box,
  Flex,
  Icon,
  Spacer,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useAdvancedProperties } from '../hooks/useAdvancedProperties';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import SteelAdvancedProperties from './SteelAdvancedProperties';
import ConcreteAdvancedProperties from './ConcreteAdvancedProperties';
import TimberAdvancedProperties from './TimberAdvancedProperties';
import GeneralAdvancedProperties from './GeneralAdvancedProperties';

export default function AdvancedProperties({
  propertyGroups,
  selectedPropertyGroup,
  standard,
  nationalAnnex,
  subcategoryPath,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  isOfficialVersion,
}) {
  const { t } = useTranslation('translation');
  const resizeSubtitles = useBreakpointValue({ base: 'sm', md: 'md' });
  const { allAdvancedProperties, show, setShow } = useAdvancedProperties(
    propertyGroups,
    selectedPropertyGroup,
    standard,
    nationalAnnex
  );

  const determineContent = () => {
    switch (subcategoryPath) {
      case 'steel':
        return (
          <SteelAdvancedProperties
            allAdvancedProperties={allAdvancedProperties}
            standard={standard}
            styleProperties={styleProperties}
            colorStyle={colorStyle}
            resizeElements={resizeElements}
            inputWidth={inputWidth}
            resizeSubtitles={resizeSubtitles}
            isOfficialVersion={isOfficialVersion}
            t={t}
          />
        );
      case 'concrete':
        return (
          <ConcreteAdvancedProperties
            allAdvancedProperties={allAdvancedProperties}
            standard={standard}
            styleProperties={styleProperties}
            colorStyle={colorStyle}
            resizeElements={resizeElements}
            inputWidth={inputWidth}
            resizeSubtitles={resizeSubtitles}
            isOfficialVersion={isOfficialVersion}
            t={t}
          />
        );
      case 'timber':
        return (
          <TimberAdvancedProperties
            allAdvancedProperties={allAdvancedProperties}
            standard={standard}
            styleProperties={styleProperties}
            colorStyle={colorStyle}
            resizeElements={resizeElements}
            inputWidth={inputWidth}
            resizeSubtitles={resizeSubtitles}
            isOfficialVersion={isOfficialVersion}
            t={t}
          />
        );
      default:
        return (
          <GeneralAdvancedProperties
            allAdvancedProperties={allAdvancedProperties}
            standard={standard}
            styleProperties={styleProperties}
            colorStyle={colorStyle}
            resizeElements={resizeElements}
            inputWidth={inputWidth}
            isOfficialVersion={isOfficialVersion}
            t={t}
          />
        );
    }
  };

  return (
    <Box>
      <Button
        fontWeight="semibold"
        mb="3"
        justifySelf="center"
        isFullWidth
        variant="outline"
        justifyContent="flex-start"
        onClick={() => setShow(!show)}
        verticalAlign="center"
      >
        <Flex w="100%" alignItems="center">
          {t('advancedProperties.advanced')}
          <Spacer />
          <Icon as={show ? FaCaretUp : FaCaretDown} />
        </Flex>
      </Button>
      {show ? determineContent() : null}
    </Box>
  );
}

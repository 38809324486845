const filterBySearchQuery = (data, searchQuery) => {
  return data?.filter(item =>
    item.name.default.toLowerCase().includes(searchQuery.toLowerCase())
  );
};

const filterByIsFavorite = (data, isFavorite) => {
  return data?.filter(item => item.isFavorite);
};

const filterByTags = (data, tagFilter) => {
  return data.filter(item => tagFilter.every(t => item.tags.includes(t)));
};

export const getFilteredSubcategoryItems = (
  data,
  searchQuery,
  tagFilter,
  isFavorite
) => {
  let dataFiltered = filterBySearchQuery(data, searchQuery);
  if (isFavorite) {
    dataFiltered = filterByIsFavorite(dataFiltered, isFavorite);
  }
  if (tagFilter.length === 0) {
    return dataFiltered;
  }
  return filterByTags(dataFiltered, tagFilter);
};

export const getTagsOfASubcategory = items => {
  const allTags = [];
  items?.map(item => {
    return item.tags.map(tag => {
      return allTags.push(tag);
    });
  });

  return [...new Set(allTags)];
};

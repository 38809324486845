import {
  IconButton,
  Center,
  Flex,
  HStack,
  StackDivider,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import CategoryAvatar from '../CategoryAvatar';
import NotFoundPage from './NotFoundPage';
import SubcategoryList from '../SubcategoryList';
import LoadingPage from './LoadingPage';
import CreateMaterialModal from '../CreateMaterialModal';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translate } from '../../services/languageService';
import { REQUEST_STATUS } from '../../hooks/useRestRequest';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { useCategorySettings } from '../../hooks/useCategorySettings';
import { HiOutlinePlus } from 'react-icons/hi';

export default function CategoryPage({ minH }) {
  const { i18n } = useTranslation('translation');
  const { t } = useTranslation('translation');
  const { categoryPath } = useParams();
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const resizeCategoryAvatar = useBreakpointValue({ base: 'md', md: 'lg' });
  const resizeDescription = useBreakpointValue({ base: 'xs', md: 'sm' });
  const lang = i18n.language;
  const { getCategory, requestStatus } = useCategoriesContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { settings, requestStatus: settingsRequestStatus } =
    useCategorySettings();

  if (
    requestStatus === REQUEST_STATUS.LOADING ||
    settingsRequestStatus === REQUEST_STATUS.LOADING
  ) {
    return <LoadingPage minH={minH} />;
  }

  const category = getCategory(categoryPath);

  if (category === undefined || settings === undefined) {
    return <NotFoundPage minH={minH} />;
  }

  const getCategorySettings = categoryId => {
    return settings.data.attributes.categories.find(c => c.id === categoryId);
  };

  return (
    <Center minH={minH} p="5">
      <VStack
        bg={categoriesBg}
        w={'950px'}
        maxW={'1100px'}
        boxShadow={'lg'}
        justify={{ base: 'center' }}
        align={{ base: 'center', md: 'center' }}
        display="flex"
        direction={['column', 'row']}
        borderWidth="1px"
        rounded={'2xl'}
        p="4"
        spacing="4"
        divider={<StackDivider />}
      >
        <Flex w="full" h="full">
          <VStack w="full" h="full">
            <HStack w={'full'} spacing="6">
              <CategoryAvatar category={category} size={resizeCategoryAvatar} />
              <Text fontWeight="semibold" fontSize={resizeCategoryAvatar}>
                {translate(category.name, lang)}
              </Text>
            </HStack>
            <Text fontSize={resizeDescription} color={'gray.500'} w={'full'}>
              {category.description === undefined
                ? '\u00A0'
                : translate(category.description, lang)}
            </Text>
          </VStack>
          <Spacer />
          <Center>
            <IconButton
              icon={<HiOutlinePlus />}
              variant="outline"
              onClick={onOpen}
            />
          </Center>
          <CreateMaterialModal
            isOpen={isOpen}
            onClose={onClose}
            lang={lang}
            subcategories={category.subcategories}
            t={t}
          />
        </Flex>
        <SubcategoryList
          category={category}
          lang={lang}
          categorySettings={getCategorySettings(category.id)}
        />
      </VStack>
    </Center>
  );
}

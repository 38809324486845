import { useRef } from 'react';
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import { LAYOUTMODES } from '../../hooks/useLayoutMode';
import { useIdentityContext } from '../../contexts/IdentityContext';
import BreadcrumbMobile from './BreadcrumbMobile';
import IdentityDisplayMobile from './IdentityDisplayMobile';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeSwitcher from './ThemeSwitcher';
import CategorySettings from './CategorySettings';

export default function HeaderMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const { isAuthenticated } = useIdentityContext();

  const showNavigation = () => {
    if (isAuthenticated) {
      return (
        <>
          <BreadcrumbMobile />
          <Spacer />
        </>
      );
    }
  };

  return (
    <Flex w="full" h="full" m="3" justifyContent="end">
      {showNavigation()}
      <IconButton
        aria-label="Open menu"
        icon={<FaBars />}
        onClick={onOpen}
        ref={btnRef}
      />
      <Drawer
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>BuildSoft Assets</DrawerHeader>
          <DrawerBody>
            <IdentityDisplayMobile />
            <Divider mt="3" mb="5" />
            <ThemeSwitcher layoutMode={LAYOUTMODES.MOBILE} />
            <Divider mt="3" mb="5" />
            <LanguageSwitcher layoutMode={LAYOUTMODES.MOBILE} />
            <Divider mt="3" mb="5" />
            <CategorySettings
              layoutMode={LAYOUTMODES.MOBILE}
              onClose={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

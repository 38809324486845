export const CategoriesUrl = `${process.env.REACT_APP_API_ENDPOINT}/categories`;
export const CategoryUrl = path => `${CategoriesUrl}/${path}`;
export const SubcategoryUrl = (categoryPath, subcategoryId) =>
  `${CategoriesUrl}/${categoryPath}/${subcategoryId}`;
export const UsersUrl = 'https://userendpoint-api.buildsofttools.com/users';
export const SettingsUrl = `${process.env.REACT_APP_API_ENDPOINT}/settings`;
export const MaterialsUrl = `${process.env.REACT_APP_API_ENDPOINT}/assets/materials/`;
export const MaterialHistoricDataUrl = assetId =>
  `${process.env.REACT_APP_API_ENDPOINT}/assets/materials/${assetId}`;
export const MaterialAssetByVersionUrl = (assetId, version) =>
  `${process.env.REACT_APP_API_ENDPOINT}/assets/materials/${assetId}/versions/${version}/sdm`;
export const UbsmMaterialAssetByVersionUrl = (assetId, version) =>
  `${process.env.REACT_APP_API_ENDPOINT}/assets/materials/${assetId}/versions/${version}/ubsm`;
export const MaterialDraft = assetId =>
  `${process.env.REACT_APP_API_ENDPOINT}/assets/materials/${assetId}/draft/sdm`;
export const UbsmMaterialDraft = assetId =>
  `${process.env.REACT_APP_API_ENDPOINT}/assets/materials/${assetId}/draft/ubsm`;

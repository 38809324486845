import {
  HStack,
  Box,
  Text,
  Button,
  Icon,
  Flex,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { getFilteredSubcategoryItems } from '../services/subcategoryService';
import { useTranslation } from 'react-i18next';
import AssetListItem from './AssetListItem';
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from 'react-icons/ai';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
} from 'react-virtualized';
import { useSortByField } from '../hooks/useSortByField';
import { useAssetList } from '../hooks/useAssetList';

export default function AssetList({
  filter,
  items,
  setItems,
  currentDbItems,
  setCurrentDbItems,
  categoryId,
  subcategoryId,
  settingsId,
}) {
  const { t } = useTranslation('translation');
  const resizeElements = useBreakpointValue({ base: 'sm', md: 'md' });
  const fieldKeyName = 'name';
  const fieldKeyTags = 'tags';
  const { currentField, sortAscending, sortDataByField } =
    useSortByField(fieldKeyName);

  const { search, tags, isFavorite } = filter.get();
  const filteredSubcategoryItems = getFilteredSubcategoryItems(
    items,
    search,
    tags,
    isFavorite
  );
  const toast = useToast();
  const { cache, onFavoriteToggle } = useAssetList(
    items,
    setItems,
    categoryId,
    subcategoryId,
    settingsId,
    currentDbItems,
    setCurrentDbItems,
    toast,
    CellMeasurerCache,
    t
  );

  const rowRenderer = ({ index, style, parent }) => {
    const item = filteredSubcategoryItems[index];
    return (
      <CellMeasurer
        key={item.id}
        cache={cache.current}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <Box ref={registerChild} key={item.id} style={style}>
            <AssetListItem
              item={item}
              onFavoriteToggle={() => onFavoriteToggle(item)}
              size={resizeElements}
            />
          </Box>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <HStack w="full" h="full">
        <Box w="5%" />
        <Box w="5%" />
        <HStack
          w="50%"
          as={Button}
          fontWeight="semibold"
          bg="transparent"
          justifyContent="flex-start"
          onClick={() =>
            sortDataByField(
              items,
              setItems,
              currentDbItems,
              setCurrentDbItems,
              fieldKeyName,
              true
            )
          }
          cursor="default"
        >
          <Flex w="100%">
            <Text ml="1" fontSize={resizeElements}>
              {t('subcategoryItems.name')}
            </Text>
            <Spacer />
            {currentField === fieldKeyName ? (
              <Icon
                fontSize="22px"
                as={
                  sortAscending
                    ? AiOutlineSortAscending
                    : AiOutlineSortDescending
                }
              />
            ) : null}
          </Flex>
        </HStack>
        <HStack
          w="40%"
          as={Button}
          fontWeight="semibold"
          bg="transparent"
          justifyContent="flex-start"
          onClick={() =>
            sortDataByField(
              items,
              setItems,
              currentDbItems,
              setCurrentDbItems,
              fieldKeyTags
            )
          }
          cursor="default"
        >
          <Flex w="100%">
            <Text fontSize={resizeElements}>{t('subcategoryItems.tags')}</Text>
            <Spacer />
            {currentField === fieldKeyTags ? (
              <Icon
                fontSize="22px"
                as={
                  sortAscending
                    ? AiOutlineSortAscending
                    : AiOutlineSortDescending
                }
              />
            ) : null}
          </Flex>
        </HStack>
      </HStack>
      <Box w="100%" h="100%">
        <Box style={{ width: '100%', height: '100%' }}>
          <WindowScroller>
            {({ height, isScrolling, scrollTop, registerChild }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Box ref={registerChild}>
                    <List
                      autoHeight
                      scrollTop={scrollTop}
                      isScrolling={isScrolling}
                      width={width}
                      height={height}
                      rowHeight={cache.current.rowHeight}
                      deferredMeasurementCache={cache.current}
                      rowCount={filteredSubcategoryItems?.length ?? 0}
                      rowRenderer={rowRenderer}
                    />
                  </Box>
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        </Box>
      </Box>
    </>
  );
}

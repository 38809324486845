import { useState, useEffect } from 'react';

export const useCreateMaterialModal = (
  subcategories,
  currentSubcategory,
  response
) => {
  const [name, setName] = useState('');
  const [displayNameError, setDisplayNameError] = useState(false);
  const [subcategory, setSubcategory] = useState(currentSubcategory?.id ?? '');
  const [displaySubcategoryError, setDisplaySubcategoryError] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!response) {
      return;
    }
    if (response.status === 200) {
      if (currentSubcategory) {
        currentSubcategory.count = currentSubcategory.count + 1;
        return;
      }
      let modifiedSubcategory = subcategories.find(
        s => s.id === response.data.data.relationships.subcategory.data.id
      );
      modifiedSubcategory.count = modifiedSubcategory.count + 1;
    }
  }, [response, currentSubcategory, subcategories]);

  return {
    name,
    setName,
    subcategory,
    setSubcategory,
    tags,
    setTags,
    tagInput,
    setTagInput,
    displayNameError,
    setDisplayNameError,
    displaySubcategoryError,
    setDisplaySubcategoryError,
    isLoading,
    setIsLoading,
  };
};

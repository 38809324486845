import { Box } from '@chakra-ui/react';
import {
  MaterialDraft,
  MaterialAssetByVersionUrl,
  UbsmMaterialAssetByVersionUrl,
  UbsmMaterialDraft,
} from '../services/urlService';
import { useMaterialDetailContent } from '../hooks/useMaterialDetailContent';
import { useGetAssetData } from '../hooks/useGetAssetData';
import { REQUEST_STATUS } from '../hooks/useRestRequest';
import NotFoundPage from '../components/pages/NotFoundPage';
import LoadingPage from '../components/pages/LoadingPage';
import MechanicalProperties from './MechanicalProperties';
import AdvancedProperties from './AdvancedProperties';
import ThermalProperties from './ThermalProperties';
import { getCurrentStandardProperties } from '../services/materialsPropertiesService';
import DetailPageHeader from './DetailPageHeader';
import { FaRegFileAlt } from 'react-icons/fa';

export default function MaterialDetailContent({
  assetId,
  subcategory,
  version,
  standard,
  nationalAnnex,
  updateNationalAnnex,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  category,
  lang,
  selectStyle,
  assetHistoricData,
  t,
  setVersion,
  setNationalAnnex,
  setStandard,
  standards,
  nationalAnnexes,
}) {
  const isOfficialVersion = assetHistoricData?.attributes.versions.some(
    x => x.version === version
  );
  const currentEndpoint = isOfficialVersion
    ? MaterialAssetByVersionUrl(assetId, version)
    : MaterialDraft(assetId);

  const { setEndpoint, data, requestStatus } = useGetAssetData(currentEndpoint);
  const { assetData } = useMaterialDetailContent(
    data,
    version,
    setEndpoint,
    currentEndpoint,
    updateNationalAnnex,
    standard
  );

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return <LoadingPage />;
  }

  if (requestStatus !== REQUEST_STATUS.SUCCESS) {
    return <NotFoundPage />;
  }

  const currentData = assetData?.find(x => x.selected === true)?.data;
  const selectedPropertyGroup = getCurrentStandardProperties(
    currentData?.propertyGroups,
    standard,
    nationalAnnex
  );

  const documentsToDownload = [
    {
      name: 'UBSM',
      icon: <FaRegFileAlt />,
      data: null,
      endpoint: isOfficialVersion
        ? UbsmMaterialAssetByVersionUrl(assetId, version)
        : UbsmMaterialDraft(assetId),
      version: version,
    },
    {
      name: 'SDM',
      icon: <FaRegFileAlt />,
      data: currentData,
      endpoint: MaterialAssetByVersionUrl(assetId, version),
      version: version,
    },
  ];

  return (
    <Box>
      <DetailPageHeader
        subcategory={subcategory}
        category={category}
        lang={lang}
        documentsToDownload={documentsToDownload}
        version={version}
        resizeElements={resizeElements}
        selectStyle={selectStyle}
        assetHistoricData={assetHistoricData}
        t={t}
        setVersion={setVersion}
        setNationalAnnex={setNationalAnnex}
        nationalAnnex={nationalAnnex}
        standard={standard}
        setStandard={setStandard}
        standards={standards}
        nationalAnnexes={nationalAnnexes}
        draftVersion={
          assetHistoricData.attributes.hasDraft
            ? {
                version: assetHistoricData.attributes.versions.length + 1,
                timestamp: assetHistoricData.attributes.lastModified,
                isDraft: true,
              }
            : null
        }
        isOfficialVersion={isOfficialVersion}
      />
      <MechanicalProperties
        styleProperties={styleProperties}
        colorStyle={colorStyle}
        propertyGroups={currentData.propertyGroups}
        selectedPropertyGroup={selectedPropertyGroup}
        standard={standard}
        nationalAnnex={nationalAnnex}
        resizeElements={resizeElements}
        inputWidth={inputWidth}
        isOfficialVersion={isOfficialVersion}
      />
      <AdvancedProperties
        styleProperties={styleProperties}
        colorStyle={colorStyle}
        propertyGroups={currentData.propertyGroups}
        selectedPropertyGroup={selectedPropertyGroup}
        standard={standard}
        nationalAnnex={nationalAnnex}
        subcategoryPath={subcategory.path}
        resizeElements={resizeElements}
        inputWidth={inputWidth}
        isOfficialVersion={isOfficialVersion}
      />
      <ThermalProperties
        styleProperties={styleProperties}
        colorStyle={colorStyle}
        propertyGroups={currentData.propertyGroups}
        selectedPropertyGroup={selectedPropertyGroup}
        standard={standard}
        nationalAnnex={nationalAnnex}
        inputWidth={inputWidth}
        resizeElements={resizeElements}
        isOfficialVersion={isOfficialVersion}
      />
    </Box>
  );
}

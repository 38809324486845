import { useState } from 'react';
import { useIdentityContext } from '../contexts/IdentityContext';
import { useRestRequest } from '../hooks/useRestRequest';

export const useGetAssetData = currentEndpoint => {
  const [endpoint, setEndpoint] = useState(currentEndpoint);
  const { authHeaders } = useIdentityContext();
  const { data, requestStatus } = useRestRequest(endpoint, authHeaders);

  return {
    endpoint,
    setEndpoint,
    data,
    requestStatus,
  };
};

import { useLocalStorage } from './useLocalStorage';
import { UsersUrl } from '../services/urlService';
import { useRestRequest } from './useRestRequest';

// in the future this will wrap Auth0 instead
// https://auth0.com/docs/quickstart/spa/react#install-the-auth0-react-sdk
// https://auth0.com/blog/complete-guide-to-react-user-authentication/

export const useIdentity = openDialog => {
  const { data: allUsers } = useRestRequest(UsersUrl);
  const [user, setUser] = useLocalStorage('user');
  const [devKey, setDevKey] = useLocalStorage('devkey', '');
  const login = () => {
    openDialog();
  };
  const logout = () => {
    setUser(null);
  };
  const setUserById = id => {
    const user = allUsers.find(u => u.id === id);
    setUser(user);
  };

  const isAuthenticated = user ? true : false;
  const isLoading = false;

  const authHeaders = {
    DevUserId: user?.id,
    DevKey: devKey,
  };

  return {
    user,
    isAuthenticated,
    isLoading,
    login,
    logout,
    setUserById,
    // only for dev login modal
    authHeaders,
    allUsers,
    devKey,
    setDevKey,
  };
};

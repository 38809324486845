import { useState, useEffect } from 'react';

export const useMaterialDetailContent = (
  data,
  version,
  setEndpoint,
  currentEndpoint,
  updateNationalAnnex,
  standard
) => {
  const [assetData, setAssetData] = useState([]);
  const addAssetVersion = (array, data) => {
    array.push({
      version: data.version,
      data: data,
      selected: true,
    });
  };

  useEffect(() => {
    const updateAssetData = () => {
      if (data) {
        setAssetData(prevState => {
          if (prevState.length === 0) {
            let newAssetData = Object.assign([], prevState);
            addAssetVersion(newAssetData, data.data.attributes);
            return newAssetData;
          }
          if (prevState.find(x => x.version === version)) {
            const newAssetData = prevState.map(element => {
              if (element.version === version) {
                element.selected = true;
                return element;
              }
              element.selected = false;
              return element;
            });
            return newAssetData;
          }
          // data is not updated yet; data.data.attributes.version !== version
          if (prevState.find(x => x.version === data.data.attributes.version)) {
            return prevState;
          }
          const newAssetData = prevState.map(element => {
            element.selected = false;
            return element;
          });
          addAssetVersion(newAssetData, data.data.attributes);
          return newAssetData;
        });
      }
    };
    updateAssetData();
  }, [data, version]);

  useEffect(() => {
    if (!assetData.find(x => x.version === version)) {
      setEndpoint(currentEndpoint);
    }
  }, [version, assetData, currentEndpoint, setEndpoint]);

  useEffect(() => {
    if (assetData.length === 0) {
      return;
    }
    const currentData = assetData?.find(x => x.selected === true)?.data;
    if (!currentData) {
      return;
    }
    updateNationalAnnex(standard, currentData);
  }, [version, standard, updateNationalAnnex, assetData]);
  return {
    assetData,
  };
};

import {
  Box,
  FormControl,
  Text,
  FormLabel,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { useTimberAdvancedProperties } from '../hooks/useTimberAdvancedProperties';
import {
  TIMBERKDEF,
  TIMBERKMOD,
  TIMBER_PHI_ETAW,
} from '../services/materialsPropertiesService';
import InputNumber from './InputNumber';
import { getFieldColor } from '../services/fieldColorService';

export default function TimberAdvancedProperties({
  allAdvancedProperties,
  standard,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  resizeSubtitles,
  isOfficialVersion,
  t,
}) {
  const localizeLabel = 'timberAdvancedProperties.';
  const {
    generalProps,
    setGeneralProps,
    kmod,
    setKmod,
    kdef,
    setKdef,
    etaWAndPhi,
    setEtaWAndPhi,
  } = useTimberAdvancedProperties(allAdvancedProperties);

  const onChangeState = (
    setState,
    key,
    newValue,
    quantityMetricConversion = 1
  ) => {
    setState(prevState => {
      let newState = Object.assign([], prevState);
      let modified = newState.find(x => x.key === key);
      modified.value = newValue * quantityMetricConversion;
      return newState;
    });
  };

  const addMetric = (text, sup) => {
    return (
      <HStack spacing="0" w="5%">
        <Flex>
          <Text fontSize="xs" fontWeight="light">
            {text}
          </Text>
          <Text as="sup" fontSize="xx-small" fontWeight="light">
            {sup}
          </Text>
        </Flex>
      </HStack>
    );
  };

  const labelWithSubIndex = (text, sub) => {
    return (
      <HStack spacing="0" w="5%" pt="2">
        <Text as="sup" fontSize="lg" fontWeight="light">
          {text}
        </Text>
        <Text as="sub" fontSize="xs" fontWeight="light">
          {sub}
        </Text>
      </HStack>
    );
  };

  const addProperty = (
    label,
    metric,
    state,
    setState,
    quantityMetricConversion = 1
  ) => {
    const props = {
      isReadOnly: isOfficialVersion,
      value:
        state !== undefined
          ? state?.value / quantityMetricConversion
          : undefined,
      step: quantityMetricConversion > 1 ? 0.1 : 1,
      onChange: val => {
        return onChangeState(
          setState,
          state.key,
          val,
          quantityMetricConversion
        );
      },
      borderLeftColor: getFieldColor(state?.standard, state?.value, standard),
      ...colorStyle,
      ...inputWidth,
    };

    return (
      <HStack mb="3" spacing="25px" key={state.key}>
        <FormLabel {...styleProperties} fontSize={resizeElements}>
          {label}
        </FormLabel>
        <InputNumber
          styleProperties={styleProperties}
          resizeElements={resizeElements}
          props={props}
        />
        {metric}
      </HStack>
    );
  };

  const addGeneralProperties = kvp => {
    let label = t(localizeLabel + kvp.key);
    let metricText = 'N/mm';
    let metricSup = '2';
    let quantityMetricConversion = 1000000;
    //special cases
    if (kvp.key === 'gamma_m') {
      quantityMetricConversion = 1;
      metricText = null;
      metricSup = null;
      label = labelWithSubIndex(t('advancedProperties.gamma'), 'M');
    }
    if (kvp.key === 'gamma_m over eta_m') {
      quantityMetricConversion = 1;
      metricText = '';
      metricSup = '';
      label = (
        <HStack spacing="1" w="5%" pt="2">
          <Text as="sup" fontSize="lg" fontWeight="light">
            {t('advancedProperties.gamma')}
          </Text>
          <Text as="sub" fontSize="xs" fontWeight="light">
            M
          </Text>
          <Text fontSize="lg" fontWeight="light">
            /
          </Text>
          <Text as="sup" fontSize="lg" fontWeight="light">
            {t('advancedProperties.eta')}
          </Text>
          <Text as="sub" fontSize="xs" fontWeight="light">
            t
          </Text>
        </HStack>
      );
    }
    if (kvp.key === 'eta_t') {
      quantityMetricConversion = 1;
      metricText = '';
      metricSup = '';
      label = labelWithSubIndex(t('advancedProperties.eta'), 't');
    }

    return addProperty(
      label,
      addMetric(metricText, metricSup),
      kvp,
      setGeneralProps,
      quantityMetricConversion
    );
  };

  const addHeaderGroups = title => {
    if (title === null) {
      return null;
    }
    const textField = n => {
      return (
        <Text
          minW="31%"
          fontSize={resizeSubtitles}
          fontWeight="hairline"
          as="u"
        >
          {t(localizeLabel + 'class') + n}
        </Text>
      );
    };
    return (
      <HStack mb="2">
        <Text fontSize={resizeSubtitles} fontWeight="hairline" w="25%" as="u">
          {title}
        </Text>
        <HStack {...inputWidth} pl="5">
          {textField(1)}
          {textField(2)}
          {textField(3)}
        </HStack>
      </HStack>
    );
  };

  const addInputGroups = (
    label,
    state,
    setState,
    key1,
    key2,
    key3,
    header = null
  ) => {
    const createProps = (currentState, key) => {
      return {
        isReadOnly: isOfficialVersion,
        value: currentState?.value,
        step: 0.1,
        onChange: val => {
          return onChangeState(setState, key, val);
        },
        borderLeftColor: getFieldColor(
          currentState?.standard,
          currentState?.value,
          standard
        ),
        ...colorStyle,
        ...inputWidth,
      };
    };
    const props1 = createProps(
      state?.find(x => x.key === key1),
      key1
    );
    const props2 = createProps(
      state?.find(x => x.key === key2),
      key2
    );
    const props3 = createProps(
      state?.find(x => x.key === key3),
      key3
    );

    if (
      props1.value === undefined &&
      props2.value === undefined &&
      props3.value === undefined
    ) {
      return null;
    }
    return (
      <Box>
        {addHeaderGroups(header)}
        <HStack mb="3" spacing="25px">
          <FormLabel {...styleProperties} fontSize={resizeElements}>
            {label}
          </FormLabel>
          <HStack {...inputWidth}>
            <InputNumber
              styleProperties={styleProperties}
              resizeElements={resizeElements}
              props={props1}
            />
            <InputNumber
              styleProperties={styleProperties}
              resizeElements={resizeElements}
              props={props2}
            />
            <InputNumber
              styleProperties={styleProperties}
              resizeElements={resizeElements}
              props={props3}
            />
          </HStack>
          <Box w="5%"></Box>
        </HStack>
      </Box>
    );
  };

  const addKModProperties = () => {
    return (
      <Box w="100%" pt="3">
        {addInputGroups(
          t(localizeLabel + 'permanent'),
          kmod,
          setKmod,
          TIMBERKMOD.KMOD1_PERMANENT,
          TIMBERKMOD.KMOD2_PERMANENT,
          TIMBERKMOD.KMOD3_PERMANENT,
          t(localizeLabel + 'kMod')
        )}
        {addInputGroups(
          t(localizeLabel + 'longTerm'),
          kmod,
          setKmod,
          TIMBERKMOD.KMOD1_LONGTERM,
          TIMBERKMOD.KMOD2_LONGTERM,
          TIMBERKMOD.KMOD3_LONGTERM
        )}
        {addInputGroups(
          t(localizeLabel + 'mediumTerm'),
          kmod,
          setKmod,
          TIMBERKMOD.KMOD1_MEDIUMTERM,
          TIMBERKMOD.KMOD2_MEDIUMTERM,
          TIMBERKMOD.KMOD3_MEDIUMTERM
        )}
        {addInputGroups(
          t(localizeLabel + 'shortTerm'),
          kmod,
          setKmod,
          TIMBERKMOD.KMOD1_SHORTTERM,
          TIMBERKMOD.KMOD2_SHORTTERM,
          TIMBERKMOD.KMOD3_SHORTTERM
        )}
        {addInputGroups(
          t(localizeLabel + 'instantaneous'),
          kmod,
          setKmod,
          TIMBERKMOD.KMOD1_INSTANTANEOUS,
          TIMBERKMOD.KMOD2_INSTANTANEOUS,
          TIMBERKMOD.KMOD3_INSTANTANEOUS
        )}
      </Box>
    );
  };

  const addKDefProperties = () => {
    return (
      <Box w="100%" pt="3">
        {addInputGroups(
          '',
          kdef,
          setKdef,
          TIMBERKDEF.KDEF1,
          TIMBERKDEF.KDEF2,
          TIMBERKDEF.KDEF3,
          t(localizeLabel + 'kDef')
        )}
      </Box>
    );
  };

  const addEtaWAndPhi = () => {
    return (
      <Box w="100%" pt="3">
        {addInputGroups(
          labelWithSubIndex(
            t('advancedProperties.eta'),
            t(localizeLabel + 'wStrength')
          ),
          etaWAndPhi,
          setEtaWAndPhi,
          TIMBER_PHI_ETAW.ETA_W1_STRENGTH,
          TIMBER_PHI_ETAW.ETA_W2_STRENGTH,
          TIMBER_PHI_ETAW.ETA_W3_STRENGTH,
          ''
        )}
        {addInputGroups(
          t(localizeLabel + 'permanent'),
          etaWAndPhi,
          setEtaWAndPhi,
          TIMBER_PHI_ETAW.ETA_MOD1_PERMANENT,
          TIMBER_PHI_ETAW.ETA_MOD2_PERMANENT,
          TIMBER_PHI_ETAW.ETA_MOD3_PERMANENT,
          labelWithSubIndex(t('advancedProperties.eta'), 'mod')
        )}
        {addInputGroups(
          t(localizeLabel + 'longTerm'),
          etaWAndPhi,
          setEtaWAndPhi,
          TIMBER_PHI_ETAW.ETA_MOD1_LONGTERM,
          TIMBER_PHI_ETAW.ETA_MOD2_LONGTERM,
          TIMBER_PHI_ETAW.ETA_MOD3_LONGTERM
        )}
        {addInputGroups(
          t(localizeLabel + 'mediumTerm'),
          etaWAndPhi,
          setEtaWAndPhi,
          TIMBER_PHI_ETAW.ETA_MOD1_MEDIUMTERM,
          TIMBER_PHI_ETAW.ETA_MOD2_MEDIUMTERM,
          TIMBER_PHI_ETAW.ETA_MOD3_MEDIUMTERM
        )}
        {addInputGroups(
          t(localizeLabel + 'shortTerm'),
          etaWAndPhi,
          setEtaWAndPhi,
          TIMBER_PHI_ETAW.ETA_MOD1_SHORTTERM,
          TIMBER_PHI_ETAW.ETA_MOD2_SHORTTERM,
          TIMBER_PHI_ETAW.ETA_MOD3_SHORTTERM
        )}
        {addInputGroups(
          t(localizeLabel + 'instantaneous'),
          etaWAndPhi,
          setEtaWAndPhi,
          TIMBER_PHI_ETAW.ETA_MOD1_INSTANTANEOUS,
          TIMBER_PHI_ETAW.ETA_MOD2_INSTANTANEOUS,
          TIMBER_PHI_ETAW.ETA_MOD3_INSTANTANEOUS
        )}
        <Box pt="3">
          {addInputGroups(
            labelWithSubIndex(t('advancedProperties.phi'), ''),
            etaWAndPhi,
            setEtaWAndPhi,
            TIMBER_PHI_ETAW.PHI1,
            TIMBER_PHI_ETAW.PHI2,
            TIMBER_PHI_ETAW.PHI3,
            ''
          )}

          {addInputGroups(
            labelWithSubIndex(
              t('advancedProperties.eta'),
              t(localizeLabel + 'wRigidity')
            ),
            etaWAndPhi,
            setEtaWAndPhi,
            TIMBER_PHI_ETAW.ETA_W1_STIFFNESS,
            TIMBER_PHI_ETAW.ETA_W2_STIFFNESS,
            TIMBER_PHI_ETAW.ETA_W3_STIFFNESS
          )}
        </Box>
      </Box>
    );
  };

  return (
    <FormControl alignContent="center" pl="5" mb="5">
      {generalProps?.map(addGeneralProperties)}
      {kmod?.length ? addKModProperties() : null}
      {kdef?.length ? addKDefProperties() : null}
      {etaWAndPhi?.length ? addEtaWAndPhi() : null}
    </FormControl>
  );
}

import { orderDictionaryByKey } from './sortingService';

export const MECHANICALPROPERTIES = {
  YOUNG_MODULUS: 'Young Modulus',
  POISSON_RATIO: 'Poisson Ratio',
  TRANSVERSAL_YOUNG_MODULUS: 'Transversal Young Modulus',
  THERMAL_DILATATION_COEFFICIENT: 'Thermal Dilatation Coefficient',
  DENSITY: 'Density',
};

export const THERMALPROPERTIES = {
  EMISSIVITY: 'Emissivity',
  CUSTOM_CONDUCTIVITY_FUNCTION: 'CustomConductivityFunction',
  CUSTOM_CAPACITY_FUNCTION: 'CustomCapacityFunction',
};

export const CONCRETEPROPERTIES = {
  FCK: 'Fck',
  FCT: 'Fct',
  GAMMA_C: 'Gamma_C',
  PHI_STRESS: 'Phi_stress',
  PHI_DEFORMATION: 'Phi_deformation',
  LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION:
    'WithLimitationForCompressiveStressInRareCombination',
  LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION_AFTER_CREEP:
    'WithLimitationForCompressiveStressInRareCombinationAfterCreep',
  SIGMA_MAX_RC: 'Sigma_max_compressive_stress_rc',
  LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT:
    'WithLimitationForCompressiveStressInQuasi_PermanentCombination',
  LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT_AFTER_CREEP:
    'WithLimitationForCompressiveStressInQuasi_PermanentCombinationAfterCreep',
  SIGMA_MAX_QPC: 'Sigma_max_compressive_stress_qpc',
  SHEAR_RESISTANCE_TAKEN_INTO_ACCOUNT: 'ShearResistanceTakenIntoAccount',
};

export const REINFORCEMENTPROPERTIES = {
  FYK: 'Fyk_reinf_long',
  FYWK: 'Fywk_reinf_trans',
  GAMMA_S: 'Gamma_S',
  MAX_RATIO_FYK: 'MaxRatio_fyk_sls_rc',
  MIN_RATIO_REINF: 'MinRatio_reinf',
  MAX_RATIO_REINF: 'MaxRatio_reinf',
};

export const TIMBERKMOD = {
  KMOD1_PERMANENT: 'k_mod1,permanent',
  KMOD2_PERMANENT: 'k_mod2,permanent',
  KMOD3_PERMANENT: 'k_mod3,permanent',
  KMOD1_LONGTERM: 'k_mod1,longterm',
  KMOD2_LONGTERM: 'k_mod2,longterm',
  KMOD3_LONGTERM: 'k_mod3,longterm',
  KMOD1_MEDIUMTERM: 'k_mod1,mediumterm',
  KMOD2_MEDIUMTERM: 'k_mod2,mediumterm',
  KMOD3_MEDIUMTERM: 'k_mod3,mediumterm',
  KMOD1_SHORTTERM: 'k_mod1,shortterm',
  KMOD2_SHORTTERM: 'k_mod2,shortterm',
  KMOD3_SHORTTERM: 'k_mod3,shortterm',
  KMOD1_INSTANTANEOUS: 'k_mod1,instantaneous',
  KMOD2_INSTANTANEOUS: 'k_mod2,instantaneous',
  KMOD3_INSTANTANEOUS: 'k_mod3,instantaneous',
};

export const TIMBERKDEF = {
  KDEF1: 'kdef_1',
  KDEF2: 'kdef_2',
  KDEF3: 'kdef_3',
};

export const TIMBER_PHI_ETAW = {
  ETA_W1_STRENGTH: 'eta_w1,strength',
  ETA_W2_STRENGTH: 'eta_w2,strength',
  ETA_W3_STRENGTH: 'eta_w3,strength',
  ETA_W1_STIFFNESS: 'eta_w1,stiffness',
  ETA_W2_STIFFNESS: 'eta_w2,stiffness',
  ETA_W3_STIFFNESS: 'eta_w3,stiffness',
  ETA_MOD1_PERMANENT: 'eta_mod1,permanent',
  ETA_MOD2_PERMANENT: 'eta_mod2,permanent',
  ETA_MOD3_PERMANENT: 'eta_mod3,permanent',
  ETA_MOD1_LONGTERM: 'eta_mod1,longterm',
  ETA_MOD2_LONGTERM: 'eta_mod2,longterm',
  ETA_MOD3_LONGTERM: 'eta_mod3,longterm',
  ETA_MOD1_MEDIUMTERM: 'eta_mod1,mediumterm',
  ETA_MOD2_MEDIUMTERM: 'eta_mod2,mediumterm',
  ETA_MOD3_MEDIUMTERM: 'eta_mod3,mediumterm',
  ETA_MOD1_SHORTTERM: 'eta_mod1,shortterm',
  ETA_MOD2_SHORTTERM: 'eta_mod2,shortterm',
  ETA_MOD3_SHORTTERM: 'eta_mod3,shortterm',
  ETA_MOD1_INSTANTANEOUS: 'eta_mod1,instantaneous',
  ETA_MOD2_INSTANTANEOUS: 'eta_mod2,instantaneous',
  ETA_MOD3_INSTANTANEOUS: 'eta_mod3,instantaneous',
  PHI1: 'phi1',
  PHI2: 'phi2',
  PHI3: 'phi3',
};

const DISCARTED = {
  USERDEFINED: 'UserDefined',
  STANDARD: 'Standard',
  IS_STRUCTURAL: 'IsStructural',
  IS_FIRE_COATING: 'IsFireCoating',
};

const getOrderTimberGeneralProps = key => {
  switch (key) {
    case 'f_y,tension':
      return 1;
    case 'f_y,compression':
      return 2;
    case 'f_y90,tension':
      return 3;
    case 'f_y90,compression':
      return 4;
    case 'f_y,moment':
      return 5;
    case 'f_y,shear':
      return 6;
    case 'gamma_m':
      return 7;
    case 'gamma_m over eta_m':
      return 8;
    case 'eta_t':
      return 9;
    default:
      return 10;
  }
};

export const getProperty = (properties, key) => {
  return properties.find(p => p.key === key);
};

export const getCurrentStandardProperties = (
  propertyGroups,
  standard,
  annex
) => {
  const currentStandard =
    annex === null || annex === 'default' ? standard : annex + '_' + standard;
  return (
    propertyGroups.find(p => p.name.default === currentStandard) ??
    propertyGroups.find(p => p.name.default === standard) ??
    propertyGroups.find(p => p.name.default === 'NotSpecified')
  );
};

export const getMechanicalProperties = (
  propertyGroups,
  selectedPropertyGroup
) => {
  const kvp = [];
  let fallbackId = selectedPropertyGroup.fallbackId;

  insertMechanicalPropertiesKvp(
    kvp,
    selectedPropertyGroup.properties,
    selectedPropertyGroup.properties.find(x => x.key === 'Standard')?.value
  );

  insertFallbackData(
    kvp,
    fallbackId,
    propertyGroups,
    insertMechanicalPropertiesKvp
  );

  return kvp;
};

export const getThermalProperties = (propertyGroups, selectedPropertyGroup) => {
  const kvp = [];
  let fallbackId = selectedPropertyGroup.fallbackId;

  insertThermalPropertiesKvp(
    kvp,
    selectedPropertyGroup.properties,
    selectedPropertyGroup.properties.find(x => x.key === 'Standard')?.value
  );

  insertFallbackData(
    kvp,
    fallbackId,
    propertyGroups,
    insertThermalPropertiesKvp
  );

  return kvp;
};

export const getAllAdvancedProperties = (
  propertyGroups,
  selectedPropertyGroup
) => {
  const kvp = [];
  let fallbackId = selectedPropertyGroup.fallbackId;

  insertAdvancedPropertiesKvp(
    kvp,
    selectedPropertyGroup.properties,
    selectedPropertyGroup.properties.find(x => x.key === 'Standard')?.value
  );

  insertFallbackData(
    kvp,
    fallbackId,
    propertyGroups,
    insertAdvancedPropertiesKvp
  );

  return kvp;
};

export const getSteelAdvancedProperties = kvp => {
  const strength = [];
  const fy = getProperty(kvp, 'f_yFunction');
  const fu = getProperty(kvp, 'f_uFunction');

  if (fy) {
    strength.push(fy);
  }
  if (fu) {
    strength.push(fu);
  }

  const safetyCoefficientDictionary = kvp.filter(x => x.key.includes('gamma'));
  const safetyCoefficient = orderDictionaryByKey(safetyCoefficientDictionary);

  return { strength, safetyCoefficient };
};

const insertMechanicalPropertiesKvp = (kvp, properties, standard) => {
  properties.map(property => {
    if (
      kvp.findIndex(x => x.key === property.key) === -1 &&
      Object.values(MECHANICALPROPERTIES).includes(property.key)
    ) {
      kvp.push({
        key: property.key,
        value: property.value,
        standard: standard,
        type: property.type,
      });
    }
    return kvp;
  });
};

export const getConcreteAdvancedProperties = kvp => {
  const concreteProperties = [];
  const reinforcementProperties = [];
  addPropertyToArray(kvp, concreteProperties, CONCRETEPROPERTIES.FCK);
  addPropertyToArray(kvp, concreteProperties, CONCRETEPROPERTIES.FCT);
  addPropertyToArray(kvp, concreteProperties, CONCRETEPROPERTIES.GAMMA_C);
  addPropertyToArray(kvp, concreteProperties, CONCRETEPROPERTIES.PHI_STRESS);
  addPropertyToArray(
    kvp,
    concreteProperties,
    CONCRETEPROPERTIES.PHI_DEFORMATION
  );
  addPropertyToArray(
    kvp,
    concreteProperties,
    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION
  );
  addPropertyToArray(kvp, concreteProperties, CONCRETEPROPERTIES.SIGMA_MAX_RC);
  addPropertyToArray(
    kvp,
    concreteProperties,
    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION_AFTER_CREEP
  );
  addPropertyToArray(
    kvp,
    concreteProperties,
    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT
  );
  addPropertyToArray(kvp, concreteProperties, CONCRETEPROPERTIES.SIGMA_MAX_QPC);
  addPropertyToArray(
    kvp,
    concreteProperties,
    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT_AFTER_CREEP
  );
  addPropertyToArray(
    kvp,
    concreteProperties,
    CONCRETEPROPERTIES.SHEAR_RESISTANCE_TAKEN_INTO_ACCOUNT
  );

  addPropertyToArray(kvp, reinforcementProperties, REINFORCEMENTPROPERTIES.FYK);
  addPropertyToArray(
    kvp,
    reinforcementProperties,
    REINFORCEMENTPROPERTIES.FYWK
  );
  addPropertyToArray(
    kvp,
    reinforcementProperties,
    REINFORCEMENTPROPERTIES.GAMMA_S
  );
  addPropertyToArray(
    kvp,
    reinforcementProperties,
    REINFORCEMENTPROPERTIES.MAX_RATIO_FYK
  );
  addPropertyToArray(
    kvp,
    reinforcementProperties,
    REINFORCEMENTPROPERTIES.MIN_RATIO_REINF
  );
  addPropertyToArray(
    kvp,
    reinforcementProperties,
    REINFORCEMENTPROPERTIES.MAX_RATIO_REINF
  );

  return { concreteProperties, reinforcementProperties };
};

export const getTimberAdvancedProperties = kvp => {
  const {
    generalPropsProperties,
    kmodProperties,
    kdefProperties,
    etaWAndPhiProperties,
  } = insertTimberProperties(kvp);

  return {
    generalPropsProperties,
    kmodProperties,
    kdefProperties,
    etaWAndPhiProperties,
  };
};

const addPropertyToArray = (propertyGroups, array, key) => {
  const property = getProperty(propertyGroups, key);
  if (property) {
    array.push(property);
  }
};

const insertAdvancedPropertiesKvp = (kvp, properties, standard) => {
  properties.map(property => {
    if (
      kvp.findIndex(x => x.key === property.key) === -1 &&
      !Object.values(MECHANICALPROPERTIES).includes(property.key) &&
      !Object.values(THERMALPROPERTIES).includes(property.key) &&
      !Object.values(DISCARTED).includes(property.key)
    ) {
      kvp.push({
        key: property.key,
        value: property.value,
        standard: standard,
        type: property.type,
      });
    }
    return kvp;
  });
};

const insertThermalPropertiesKvp = (kvp, properties, standard) => {
  properties.map(property => {
    if (
      kvp.findIndex(x => x.key === property.key) === -1 &&
      Object.values(THERMALPROPERTIES).includes(property.key)
    ) {
      kvp.push({
        key: property.key,
        value: property.value,
        standard: standard,
        type: property.type,
      });
    }
    return kvp;
  });
};

const insertFallbackData = (
  kvpArray,
  fallbackId,
  propertyGroups,
  insertFunction
) => {
  const updateFallbackId = newFallbackId => (fallbackId = newFallbackId);
  const getFallbackId = () => {
    return fallbackId;
  };

  while (fallbackId) {
    let fallbackProperties = propertyGroups.find(p => p.id === getFallbackId());
    let fallbackStandard = fallbackProperties.properties.find(
      k => k.key === 'Standard'
    );
    insertFunction(
      kvpArray,
      fallbackProperties.properties,
      fallbackStandard.value
    );
    updateFallbackId(fallbackProperties.fallbackId);
  }
};

const insertTimberProperties = kvp => {
  const generalProps = [];
  const kmodProperties = [];
  const kdefProperties = [];
  const etaWAndPhiProperties = [];

  kvp.map(property => {
    // not showed in Diamonds
    if (
      property.key === 'Timber Class' ||
      property.key === 'Capacitytype' ||
      property.key === 'Conductivitytype'
    ) {
      return null;
    }
    if (property.value <= 0) {
      return null;
    }
    // fill objects
    if (Object.values(TIMBERKMOD).includes(property.key)) {
      kmodProperties.push(property);
      return kmodProperties;
    }

    if (Object.values(TIMBERKDEF).includes(property.key)) {
      kdefProperties.push(property);
      return kdefProperties;
    }

    if (Object.values(TIMBER_PHI_ETAW).includes(property.key)) {
      etaWAndPhiProperties.push(property);
      return etaWAndPhiProperties;
    }
    generalProps.push(property);
    return generalProps;
  });

  const generalPropsProperties = generalProps.sort((a, b) => {
    let aOrder = getOrderTimberGeneralProps(a.key);
    let bOrder = getOrderTimberGeneralProps(b.key);
    return aOrder > bOrder ? 1 : aOrder < bOrder ? -1 : 0;
  });

  return {
    generalPropsProperties,
    kmodProperties,
    kdefProperties,
    etaWAndPhiProperties,
  };
};

import { useState, useEffect } from 'react';
import {
  MECHANICALPROPERTIES,
  getMechanicalProperties,
  getProperty,
} from '../services/materialsPropertiesService';

export const useMechanicalProperties = (
  propertyGroups,
  selectedPropertyGroup,
  standard,
  nationalAnnex
) => {
  const [young, setYoung] = useState();
  const [poisson, setPoisson] = useState();
  const [transverseYoung, setTransverseYoung] = useState();
  const [dilatation, setDilatation] = useState();
  const [density, setDensity] = useState();
  const [show, setShow] = useState(true);

  useEffect(() => {
    const updateStates = () => {
      if (propertyGroups.length === 0) {
        return;
      }
      const mechanicalProperties = getMechanicalProperties(
        propertyGroups,
        selectedPropertyGroup
      );
      setYoung(
        getProperty(mechanicalProperties, MECHANICALPROPERTIES.YOUNG_MODULUS)
      );
      setPoisson(
        getProperty(mechanicalProperties, MECHANICALPROPERTIES.POISSON_RATIO)
      );
      setTransverseYoung(
        getProperty(
          mechanicalProperties,
          MECHANICALPROPERTIES.TRANSVERSAL_YOUNG_MODULUS
        )
      );
      setDilatation(
        getProperty(
          mechanicalProperties,
          MECHANICALPROPERTIES.THERMAL_DILATATION_COEFFICIENT
        )
      );
      setDensity(
        getProperty(mechanicalProperties, MECHANICALPROPERTIES.DENSITY)
      );
    };
    updateStates();
  }, [propertyGroups, standard, nationalAnnex, selectedPropertyGroup]);

  return {
    young,
    setYoung,
    poisson,
    setPoisson,
    transverseYoung,
    setTransverseYoung,
    dilatation,
    setDilatation,
    density,
    setDensity,
    show,
    setShow,
  };
};

import {
  Box,
  Checkbox,
  FormControl,
  Text,
  FormLabel,
  HStack,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { useConcreteAdvancedProperties } from '../hooks/useConcreteAdvancedProperties';
import {
  CONCRETEPROPERTIES,
  REINFORCEMENTPROPERTIES,
} from '../services/materialsPropertiesService';
import InputNumber from './InputNumber';
import { getFieldColor } from '../services/fieldColorService';

export default function ConcreteAdvancedProperties({
  allAdvancedProperties,
  standard,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  resizeSubtitles,
  isOfficialVersion,
  t,
}) {
  const { concrete, setConcrete, reinforcement, setReinforcement } =
    useConcreteAdvancedProperties(allAdvancedProperties);

  const labelWithSubIndex = (text, sub) => {
    return (
      <HStack spacing="0" w="5%" pt="2">
        <Text as="sup" fontSize="lg" fontWeight="light">
          {text}
        </Text>
        <Text as="sub" fontSize="xs" fontWeight="light">
          {sub}
        </Text>
      </HStack>
    );
  };

  const onChangeState = (
    setState,
    key,
    newValue,
    quantityMetricConversion = 1
  ) => {
    setState(prevState => {
      let newState = Object.assign([], prevState);
      let modified = newState.find(x => x.key === key);
      modified.value = newValue * quantityMetricConversion;
      return newState;
    });
  };

  const addMetric = (text, sup) => {
    return (
      <HStack spacing="0" w="5%">
        <Flex>
          <Text fontSize="xs" fontWeight="light" isTruncated>
            {text}
          </Text>
          <Text as="sup" fontSize="xx-small" fontWeight="light">
            {sup}
          </Text>
        </Flex>
      </HStack>
    );
  };

  const addProperty = (
    label,
    metric,
    state,
    setState,
    quantityMetricConversion = 1
  ) => {
    const inputNumberProps = {
      isReadOnly: isOfficialVersion,
      step: quantityMetricConversion > 1 ? 0.1 : 1,
      value:
        state !== undefined
          ? state?.value / quantityMetricConversion
          : undefined,
      onChange: val => {
        return onChangeState(
          setState,
          state.key,
          val,
          quantityMetricConversion
        );
      },
      borderLeftColor: getFieldColor(state?.standard, state?.value, standard),
      ...colorStyle,
      ...inputWidth,
    };
    return (
      <HStack mb="3" spacing="25px">
        <FormLabel {...styleProperties} fontSize={resizeElements}>
          {label}
        </FormLabel>
        <InputNumber
          styleProperties={styleProperties}
          resizeElements={resizeElements}
          props={inputNumberProps}
        />
        {metric}
      </HStack>
    );
  };

  return (
    <FormControl alignContent="center" pl="5" mb="5">
      <Box mb="5">
        <Text fontSize={resizeSubtitles} fontWeight="hairline" as="u">
          {t('concreteAdvancedProperties.concrete')}
        </Text>
      </Box>
      {addProperty(
        t('concreteAdvancedProperties.fkc'),
        addMetric('N/mm', '2'),
        concrete?.find(x => x.key === CONCRETEPROPERTIES.FCK),
        setConcrete,
        1000000
      )}
      {addProperty(
        t('concreteAdvancedProperties.fct'),
        addMetric('N/mm', '2'),
        concrete?.find(x => x.key === CONCRETEPROPERTIES.FCT),
        setConcrete,
        1000000
      )}

      {addProperty(
        labelWithSubIndex(t('advancedProperties.gamma'), 'c'),
        addMetric(' ', ' '),
        concrete?.find(x => x.key === CONCRETEPROPERTIES.GAMMA_C),
        setConcrete
      )}
      {addProperty(
        t('concreteAdvancedProperties.phiStress'),
        addMetric(' ', ' '),
        concrete?.find(x => x.key === CONCRETEPROPERTIES.PHI_STRESS),
        setConcrete
      )}
      {addProperty(
        t('concreteAdvancedProperties.phiDeformation'),
        addMetric(' ', ' '),
        concrete?.find(x => x.key === CONCRETEPROPERTIES.PHI_DEFORMATION),
        setConcrete
      )}
      <VStack w="100%" align="stretch" pb="3">
        <Checkbox
          isReadOnly={isOfficialVersion}
          size="sm"
          mt="1"
          value={
            concrete?.find(
              x =>
                x.key ===
                CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION
            )?.value
          }
          isChecked={
            concrete?.find(
              x =>
                x.key ===
                CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION
            )?.value
          }
          onChange={() => {
            return onChangeState(
              setConcrete,
              CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION,
              !concrete?.find(
                x =>
                  x.key ===
                  CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION
              )?.value
            );
          }}
        >
          <Text fontSize={resizeElements} fontWeight="light">
            {t('concreteAdvancedProperties.limitCompressiveStressRC')}
          </Text>
        </Checkbox>
        <HStack spacing={'4rem'} alignContent="center" h="100%">
          <Box></Box>
          {addProperty(
            '',
            addMetric('N/mm', '2'),
            concrete?.find(x => x.key === CONCRETEPROPERTIES.SIGMA_MAX_RC),
            setConcrete,
            1000000
          )}
          <Box h="100%" alignContent="top" mt="-3">
            <Checkbox
              isReadOnly={isOfficialVersion}
              size="sm"
              mt="1"
              value={
                concrete?.find(
                  x =>
                    x.key ===
                    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION_AFTER_CREEP
                )?.value
              }
              isChecked={
                concrete?.find(
                  x =>
                    x.key ===
                    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION_AFTER_CREEP
                )?.value
              }
              onChange={() => {
                return onChangeState(
                  setConcrete,
                  CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION_AFTER_CREEP,
                  !concrete?.find(
                    x =>
                      x.key ===
                      CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_RARE_COMBINATION_AFTER_CREEP
                  )?.value
                );
              }}
            >
              <Text fontSize={resizeElements} fontWeight="light">
                {t('concreteAdvancedProperties.afterCreep')}
              </Text>
            </Checkbox>
          </Box>
        </HStack>
      </VStack>
      <VStack w="100%" align="stretch" mb="4">
        <Checkbox
          isReadOnly={isOfficialVersion}
          mt="1"
          size="sm"
          value={
            concrete?.find(
              x =>
                x.key ===
                CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT
            )?.value
          }
          isChecked={
            concrete?.find(
              x =>
                x.key ===
                CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT
            )?.value
          }
          onChange={() => {
            return onChangeState(
              setConcrete,
              CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT,
              !concrete?.find(
                x =>
                  x.key ===
                  CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT
              )?.value
            );
          }}
        >
          <Text fontSize={resizeElements} fontWeight="light">
            {t('concreteAdvancedProperties.limitCompressiveStressQP')}
          </Text>
        </Checkbox>
        <HStack spacing={'4rem'} alignContent="center" h="100%">
          <Box></Box>
          {addProperty(
            '',
            addMetric('N/mm', '2'),
            concrete?.find(x => x.key === CONCRETEPROPERTIES.SIGMA_MAX_QPC),
            setConcrete,
            1000000
          )}
          <Checkbox
            isReadOnly={isOfficialVersion}
            mt="1"
            size="sm"
            value={
              concrete?.find(
                x =>
                  x.key ===
                  CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT_AFTER_CREEP
              )?.value
            }
            isChecked={
              concrete?.find(
                x =>
                  x.key ===
                  CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT_AFTER_CREEP
              )?.value
            }
            onChange={() => {
              return onChangeState(
                setConcrete,
                CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT_AFTER_CREEP,
                !concrete?.find(
                  x =>
                    x.key ===
                    CONCRETEPROPERTIES.LIMITATION_COMPRESSIVE_STRESS_QUASI_PERMANENT_AFTER_CREEP
                )?.value
              );
            }}
          >
            <Text fontSize={resizeElements} fontWeight="light">
              {t('concreteAdvancedProperties.afterCreep')}
            </Text>
          </Checkbox>
        </HStack>
      </VStack>
      {addProperty(
        t('concreteAdvancedProperties.shearResistance'),
        addMetric('%', ''),
        concrete?.find(
          x => x.key === CONCRETEPROPERTIES.SHEAR_RESISTANCE_TAKEN_INTO_ACCOUNT
        ),
        setConcrete
      )}
      <Box mb="5">
        <Text fontSize={resizeSubtitles} fontWeight="hairline" as="u">
          {t('concreteAdvancedProperties.reinforcement')}
        </Text>
      </Box>
      {addProperty(
        t('concreteAdvancedProperties.fyk'),
        addMetric('N/mm', '2'),
        reinforcement?.find(x => x.key === REINFORCEMENTPROPERTIES.FYK),
        setReinforcement,
        1000000
      )}
      {addProperty(
        t('concreteAdvancedProperties.fywk'),
        addMetric('N/mm', '2'),
        reinforcement?.find(x => x.key === REINFORCEMENTPROPERTIES.FYWK),
        setReinforcement,
        1000000
      )}
      {addProperty(
        labelWithSubIndex(t('advancedProperties.gamma'), 's'),
        addMetric('', ''),
        reinforcement?.find(x => x.key === REINFORCEMENTPROPERTIES.GAMMA_S),
        setReinforcement
      )}
      {addProperty(
        t('concreteAdvancedProperties.maxRatioFyk'),
        addMetric('% x fyk', ''),
        reinforcement?.find(
          x => x.key === REINFORCEMENTPROPERTIES.MAX_RATIO_FYK
        ),
        setReinforcement,
        0.01
      )}
      {addProperty(
        t('concreteAdvancedProperties.minRatioReinf'),
        addMetric('%', ''),
        reinforcement?.find(
          x => x.key === REINFORCEMENTPROPERTIES.MIN_RATIO_REINF
        ),
        setReinforcement,
        0.01
      )}
      {addProperty(
        t('concreteAdvancedProperties.maxRatioReinf'),
        addMetric('%', ''),
        reinforcement?.find(
          x => x.key === REINFORCEMENTPROPERTIES.MAX_RATIO_REINF
        ),
        setReinforcement,
        0.01
      )}
    </FormControl>
  );
}

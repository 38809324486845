import { MenuItem, Icon, HStack, Text, useStyleConfig } from '@chakra-ui/react';
import { LAYOUTMODES } from '../../hooks/useLayoutMode';
import { useTranslation } from 'react-i18next';
import { GoSettings } from 'react-icons/go';
import { NavLink } from 'react-router-dom';

export default function CategorySettings({
  layoutMode = LAYOUTMODES.DESKTOP,
  onClose,
}) {
  const settingsPage = 'settings';
  const { t } = useTranslation('translation');
  const { _active, _focus, _disabled } = useStyleConfig('Button');

  const buttonStyle = {
    h: '10',
    borderRadius: 'md',
    _hover: { disabled: { bg: 'initial' }, bg: 'whiteAlpha.200' },
    _disabled: { _disabled },
    _active: { _active },
    _focus: { _focus },
  };

  if (layoutMode === LAYOUTMODES.MOBILE) {
    return (
      <HStack
        mb="1"
        as={NavLink}
        to={settingsPage}
        onClick={onClose}
        {...buttonStyle}
      >
        <Icon as={GoSettings} size="16" />
        <Text fontSize="lg">{t('categorySettings.settings')}</Text>
      </HStack>
    );
  }

  return (
    <MenuItem
      pl="0"
      icon={<GoSettings size="16" />}
      as={NavLink}
      to={settingsPage}
      size="md"
      color="current"
      fontWeight="semibold"
    >
      {t('categorySettings.settings')}
    </MenuItem>
  );
}

import { Routes, Route } from 'react-router-dom';
import CategoryPage from './pages/CategoryPage';
import IfAuthenticated from './IfAuthenticated';
import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/NotFoundPage';
import SubcategoryPage from './pages/SubcategoryPage';
import DetailPage from './pages/DetailPage';
import CategorySettingsPage from './pages/CategorySettingsPage';

export default function Content({ minH }) {
  return (
    <Routes>
      <Route path="/" element={<LandingPage minH={minH} />} />
      <Route
        path="/:categoryPath"
        element={<IfAuthenticated minH={minH} page={CategoryPage} />}
      />
      <Route
        path="/:categoryPath/:subcategoryPath"
        element={<IfAuthenticated minH={minH} page={SubcategoryPage} />}
      />
      <Route
        path="/:categoryPath/:subcategoryPath/:assetId"
        element={<IfAuthenticated minH={minH} page={DetailPage} />}
      />
      <Route
        path="/settings"
        element={<IfAuthenticated minH={minH} page={CategorySettingsPage} />}
      />
      <Route path="*" element={<NotFoundPage minH={minH} />} />
    </Routes>
  );
}

import { useState } from 'react';
import {
  sortFieldAscending,
  sortFieldDescending,
} from '../services/sortingService';

export const useSortByField = initialField => {
  const [currentField, setCurrentField] = useState(initialField);
  const [sortAscending, setSortAscending] = useState(true);

  const sortDataByField = (
    data,
    setData,
    databaseData,
    setDatabaseData,
    property,
    isLocalized = false
  ) => {
    if (currentField !== property) {
      setCurrentField(property);
      setSortAscending(true);
      setData(sortFieldAscending(data, property, isLocalized));
      setDatabaseData(sortFieldAscending(databaseData, property, isLocalized));
      return;
    }

    if (!sortAscending) {
      setSortAscending(true);
      setData(sortFieldAscending(data, property, isLocalized));
      setDatabaseData(sortFieldAscending(databaseData, property, isLocalized));
      return;
    }

    setSortAscending(false);
    setData(sortFieldDescending(data, property, isLocalized));
    setDatabaseData(sortFieldDescending(databaseData, property, isLocalized));
  };

  return { currentField, sortAscending, sortDataByField };
};

import {
  FormControl,
  FormLabel,
  HStack,
  Box,
  Input,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useGeneralAdvancedProperties } from '../hooks/useGeneralAdvancedProperties';
import InputNumber from './InputNumber';
import FFunction from './FFunction';
import { getFieldColor } from '../services/fieldColorService';

export default function GeneralAdvancedProperties({
  allAdvancedProperties,
  standard,
  styleProperties,
  colorStyle,
  resizeElements,
  inputWidth,
  isOfficialVersion,
  t,
}) {
  const inputResize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const { allProperties, setAllProperties } = useGeneralAdvancedProperties(
    allAdvancedProperties
  );

  const onChangeState = (key, newValue) => {
    setAllProperties(prevState => {
      let newState = Object.assign([], prevState);
      let modified = newState.find(x => x.key === key);
      modified.value = newValue;
      return newState;
    });
  };

  const inputValue = (property, borderColor) => {
    if (typeof property.value === 'string') {
      return (
        <Input
          {...styleProperties}
          {...inputWidth}
          {...colorStyle}
          borderLeftColor={borderColor}
          _hover={{
            bg: 'none',
          }}
          _focus={{
            borderColor: borderColor,
          }}
          size={inputResize}
          value={property?.value}
          onChange={event => {
            onChangeState(property.key, event.target.value);
          }}
          isReadOnly={isOfficialVersion}
        />
      );
    }
    const inputNumberProps = {
      isReadOnly: isOfficialVersion,
      value: property?.value,
      onChange: val => {
        onChangeState(property.key, parseFloat(val));
      },
      borderLeftColor: borderColor,
      ...colorStyle,
      ...inputWidth,
    };
    return (
      <InputNumber
        styleProperties={styleProperties}
        resizeElements={resizeElements}
        props={inputNumberProps}
      />
    );
  };

  const addProperty = property => {
    let borderColor = getFieldColor(
      property?.standard,
      property?.value,
      standard
    );
    if (property.type === 'pointsFunction') {
      if (property.value.type === 'stepped') {
        let positionPoint = 100 / (property.value.points.length - 1) ?? 1;
        let currentPositionPoint = 0;
        return (
          <Box mb="3" key={property.key}>
            <FFunction
              property={property}
              currentPositionPoint={currentPositionPoint}
              positionPoint={positionPoint}
              styleProperties={styleProperties}
              resizeElements={resizeElements}
              t={t}
              colorStyle={colorStyle}
              inputWidth={inputWidth}
              setStrengthFunctions={setAllProperties}
              borderLeftColor={borderColor}
            />
          </Box>
        );
      }
    }
    return (
      <HStack mb="3" spacing="25px" key={property.key}>
        <FormLabel {...styleProperties} fontSize={resizeElements}>
          {t('generalAdvancedProperties.' + property.key)}
        </FormLabel>
        {inputValue(property, borderColor)}
        <Box w="5%" />
      </HStack>
    );
  };

  return (
    <FormControl alignContent="center" pl="5" mb="5">
      {allProperties?.map(addProperty)}
    </FormControl>
  );
}

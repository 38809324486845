import { VStack, useColorModeValue, StackDivider } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import SubcategoryListItem from './SubcategoryListItem';
import { orderSubcategories } from '../services/sortingService';

export default function SubcategoryList({ category, lang, categorySettings }) {
  const categoriesBg = useColorModeValue('gray.50', 'gray.900');
  const resizeElements = useBreakpointValue({ base: 'sm', md: 'md' });

  const subcategories = () =>
    orderSubcategories(
      categorySettings?.subcategories ?? [],
      category.subcategories,
      lang,
      categorySettings?.hideEmptySubcategories
    );

  return (
    <VStack
      borderWidth="1px"
      rounded={'2xl'}
      bg={categoriesBg}
      boxShadow={'lg'}
      justify={{ base: 'center' }}
      align={{ base: 'center', md: 'center' }}
      spacing="0"
      display="flex"
      w="full"
      h="full"
      direction={['column', 'row']}
      divider={<StackDivider />}
    >
      {subcategories().map(subcategory => {
        return (
          <SubcategoryListItem
            key={subcategory.id}
            subcategory={subcategory}
            lang={lang}
            size={resizeElements}
          />
        );
      })}
    </VStack>
  );
}

import { useState, useEffect } from 'react';
import { getAllAdvancedProperties } from '../services/materialsPropertiesService';

export const useAdvancedProperties = (
  propertyGroups,
  selectedPropertyGroup,
  standard,
  nationalAnnex
) => {
  const [allAdvancedProperties, setAllAdvancedProperties] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (propertyGroups.length === 0) {
      return;
    }
    setAllAdvancedProperties(
      getAllAdvancedProperties(propertyGroups, selectedPropertyGroup)
    );
  }, [propertyGroups, standard, nationalAnnex, selectedPropertyGroup]);

  return {
    allAdvancedProperties,
    setAllAdvancedProperties,
    show,
    setShow,
  };
};

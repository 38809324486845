import { useState, useEffect } from 'react';
import { getConcreteAdvancedProperties } from '../services/materialsPropertiesService';

export const useConcreteAdvancedProperties = advancedProperties => {
  const [concrete, setConcrete] = useState();
  const [reinforcement, setReinforcement] = useState();

  useEffect(() => {
    const updateStates = () => {
      if (!advancedProperties) {
        return;
      }
      const { concreteProperties, reinforcementProperties } =
        getConcreteAdvancedProperties(advancedProperties);
      setConcrete(concreteProperties);
      setReinforcement(reinforcementProperties);
    };

    updateStates();
  }, [advancedProperties]);

  return {
    concrete,
    setConcrete,
    reinforcement,
    setReinforcement,
  };
};

import { useState, useEffect } from 'react';
import { useIdentityContext } from '../contexts/IdentityContext';
import { MaterialsUrl, SubcategoryUrl } from '../services/urlService';
import { useCategorySettings } from '../hooks/useCategorySettings';
import { useRestRequest } from '../hooks/useRestRequest';

export const useSubcategoryPageContent = (
  categoryId,
  subcategoryId,
  categoryPath
) => {
  const { authHeaders } = useIdentityContext();
  const { settings } = useCategorySettings();
  const url =
    categoryPath === 'materials'
      ? MaterialsUrl
      : SubcategoryUrl(categoryPath, subcategoryId);
  const { data: allSubcategoryItems, requestStatus } = useRestRequest(
    url,
    authHeaders
  );

  const [items, setItems] = useState();
  const [currentDbItems, setCurrentDbItems] = useState();
  const [userSettings, setUserSettings] = useState(settings);

  useEffect(() => {
    const formatData = allSubcategoryItems => {
      if (categoryPath !== 'materials') {
        return allSubcategoryItems.map(item => {
          const attributes = {
            hasDraft: false,
            lastModified: item.lastChanged,
            name: { default: item.name },
            tags: item.labels,
            versions: [],
          };
          return {
            id: item.id,
            ...attributes,
          };
        });
      }
      return allSubcategoryItems?.data
        .filter(x => x.relationships.subcategory.data.id === subcategoryId)
        .map(item => {
          return { id: item.id, ...item.attributes };
        });
    };

    const updateItems = () => {
      if (!allSubcategoryItems) {
        return;
      }

      const filteredSubcategoryItems = formatData(allSubcategoryItems);
      const favoritesList =
        settings?.data.attributes.categories
          ?.find(x => x.id === categoryId)
          ?.subcategories?.find(s => s.id === subcategoryId)?.favorites ?? [];

      const itemList = filteredSubcategoryItems?.map(subcategoryItem => {
        favoritesList.includes(subcategoryItem.id)
          ? (subcategoryItem.isFavorite = true)
          : (subcategoryItem.isFavorite = false);
        return subcategoryItem;
      });
      setItems(
        itemList?.sort((a, b) => {
          return a.name.default > b.name.default
            ? 1
            : a.name.default < b.name.default
            ? -1
            : 0;
        })
      );
      setCurrentDbItems(
        itemList?.sort((a, b) => {
          return a.name.default > b.name.default
            ? 1
            : a.name.default < b.name.default
            ? -1
            : 0;
        })
      );
    };
    updateItems();
  }, [allSubcategoryItems, settings, categoryId, subcategoryId, categoryPath]);

  useEffect(() => {
    setUserSettings(settings);
  }, [settings]);

  return {
    requestStatus,
    userSettings,
    items,
    setItems,
    currentDbItems,
    setCurrentDbItems,
  };
};

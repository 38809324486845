import { useState, useEffect } from 'react';
import {
  getThermalProperties,
  getProperty,
  THERMALPROPERTIES,
} from '../services/materialsPropertiesService';

export const useThermalProperties = (
  propertyGroups,
  selectedPropertyGroup,
  standard,
  nationalAnnex
) => {
  const [emissivity, setEmissivity] = useState();
  const [customCapacityFunction, setCustomCapacityFunction] = useState();
  const [customConductivityFunction, setConductivityFunction] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const updateStates = () => {
      if (propertyGroups.length === 0) {
        return;
      }
      const thermalProperties = getThermalProperties(
        propertyGroups,
        selectedPropertyGroup
      );

      setEmissivity(
        getProperty(thermalProperties, THERMALPROPERTIES.EMISSIVITY)
      );
      setCustomCapacityFunction(
        getProperty(
          thermalProperties,
          THERMALPROPERTIES.CUSTOM_CAPACITY_FUNCTION
        )
      );
      setConductivityFunction(
        getProperty(
          thermalProperties,
          THERMALPROPERTIES.CUSTOM_CONDUCTIVITY_FUNCTION
        )
      );
    };
    updateStates();
  }, [propertyGroups, standard, nationalAnnex, selectedPropertyGroup]);

  return {
    emissivity,
    setEmissivity,
    customCapacityFunction,
    setCustomCapacityFunction,
    customConductivityFunction,
    setConductivityFunction,
    show,
    setShow,
  };
};

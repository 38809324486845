import {
  Box,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
  Select,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import SubcategoryAvatar from './SubcategoryAvatar';
import CreateMaterialModal from './CreateMaterialModal';
import { translate } from '../services/languageService';
import { MdFileDownload } from 'react-icons/md';
import { BiDuplicate } from 'react-icons/bi';
import { useGetAssetData } from '../hooks/useGetAssetData';
import { useDetailPageHeader } from '../hooks/useDetailPageHeader';

export default function DetailPageHeader({
  subcategory,
  category,
  lang,
  documentsToDownload,
  version,
  resizeElements,
  selectStyle,
  assetHistoricData,
  t,
  setVersion,
  setNationalAnnex,
  nationalAnnex,
  standard,
  setStandard,
  standards,
  nationalAnnexes,
  draftVersion,
  isOfficialVersion,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setEndpoint, endpoint, data } = useGetAssetData(null);
  const { setFileToDownload, materialName, setMaterialName } =
    useDetailPageHeader(data, endpoint, assetHistoricData);

  const onDownload = (file, fileData, name) => {
    const fileName =
      file.name + '_' + name + '_Version_' + file.version + '.json';
    const blob = new Blob([JSON.stringify(fileData, null, 2)], {
      type: 'application/json',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addMenuItem = file => {
    return (
      <MenuItem
        key={file.name}
        icon={file.icon}
        onClick={() => {
          if (file.data) {
            return onDownload(file, file.data, materialName);
          }
          if (!file.data) {
            if (endpoint === file.endpoint && data) {
              return onDownload(file, data, materialName);
            }
            setEndpoint(file.endpoint);
            setFileToDownload(file);
            return;
          }
        }}
      >
        {file.name}
      </MenuItem>
    );
  };

  const versionList = version => {
    if (!version) {
      return;
    }
    const selectValue =
      t('materialDetail.version') +
      version.version +
      ' {' +
      new Date(version.timestamp).toLocaleDateString() +
      '}';
    return (
      <option value={version.version} key={version.version}>
        {version?.isDraft
          ? `${selectValue} (${t('common.draft')})`
          : selectValue}
      </option>
    );
  };

  const standardList = st => {
    return (
      <option value={st.standard} key={st.standard}>
        {st.name === 'Default' ? t('materialDetail.default') : st.name}
      </option>
    );
  };

  const annexesList = annex => {
    return (
      <option value={annex.standard} key={annex.standard}>
        {annex.name}
      </option>
    );
  };

  const updateVersion = selectedVersion => {
    setVersion(selectedVersion);
  };

  return (
    <Box>
      <HStack w="full" h="full" spacing="6" pb="5">
        <SubcategoryAvatar subcategory={subcategory} />
        <Text fontWeight="semibold" verticalAlign="center" w="90%">
          {translate(category.name, lang)}
          {' - '}
          {translate(subcategory.name, lang)}
        </Text>
        <IconButton
          icon={<BiDuplicate size="18" />}
          variant="outline"
          onClick={onOpen}
        />
        {documentsToDownload ? (
          <Box>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<MdFileDownload size="18" />}
                variant="outline"
              />
              <MenuList>
                {documentsToDownload
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : a.name.toLowerCase() < b.name.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map(addMenuItem)}
              </MenuList>
            </Menu>
          </Box>
        ) : null}
        <CreateMaterialModal
          isOpen={isOpen}
          onClose={onClose}
          lang={lang}
          currentSubcategory={subcategory}
          t={t}
          assetId={assetHistoricData.id}
        />
      </HStack>
      <Input
        fontSize={resizeElements}
        mb="3"
        readOnly={isOfficialVersion}
        value={materialName}
        onChange={event => {
          setMaterialName(event.target.value);
        }}
      ></Input>
      <Input
        fontSize={resizeElements}
        mb="3"
        readOnly
        value={assetHistoricData.id}
      ></Input>
      <Select
        fontSize={resizeElements}
        mb="5"
        value={version}
        onChange={event => {
          updateVersion(parseInt(event.target.value));
        }}
      >
        {assetHistoricData.attributes.versions.map(versionList)}
        {versionList(draftVersion)}
      </Select>
      <Divider mb="5" />
      <HStack mb="10">
        <Select
          w="50%"
          fontSize={resizeElements}
          value={standard}
          borderLeftColor={standard === 'NotSpecified' ? 'grey' : 'blue.500'}
          {...selectStyle}
          onChange={event => {
            setStandard(event.target.value);
          }}
        >
          {standards.map(standardList)}
        </Select>
        <Box w="50%" hidden={nationalAnnex === null ? true : false}>
          <Select
            fontSize={resizeElements}
            value={nationalAnnex ?? ''}
            {...selectStyle}
            borderLeftColor={'orange.500'}
            onChange={event => setNationalAnnex(event.target.value)}
          >
            <option value="default">--</option>
            {nationalAnnexes.map(annexesList)}
          </Select>
        </Box>
      </HStack>
    </Box>
  );
}

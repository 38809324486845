import { useState, useEffect } from 'react';

export const useDetailPageHeader = (data, endpoint, assetHistoricData) => {
  const [fileToDownload, setFileToDownload] = useState();
  const [materialName, setMaterialName] = useState(
    assetHistoricData.attributes.name.default
  );

  useEffect(() => {
    const download = (file, fileData) => {
      const fileName =
        file.name + '_' + materialName + '_Version_' + file.version + '.json';
      const blob = new Blob([JSON.stringify(fileData, null, 2)], {
        type: 'application/json',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const downloadDocument = () => {
      if (!data) {
        return;
      }
      if (fileToDownload?.endpoint !== endpoint) {
        return;
      }
      if (
        data?.data.attributes.definition.version !== fileToDownload?.version
      ) {
        return;
      }
      download(fileToDownload, data);
    };

    downloadDocument();
  }, [data, endpoint, materialName, fileToDownload]);

  return { setFileToDownload, materialName, setMaterialName };
};

import {
  Box,
  Flex,
  HStack,
  Input,
  FormLabel,
  Text,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  IconButton,
} from '@chakra-ui/react';
import { LAYOUTMODES, useLayoutMode } from '../hooks/useLayoutMode';
import InputNumber from './InputNumber';
import { MdModeEdit } from 'react-icons/md';

export default function FFunction({
  property,
  currentPositionPoint,
  positionPoint,
  borderLeftColor,
  styleProperties,
  resizeElements,
  inputWidth,
  t,
  colorStyle,
  setStrengthFunctions,
  isOfficialVersion,
}) {
  var { mode } = useLayoutMode();
  const nPoints = property.value.points.length;

  const addMobileMetrics = name => {
    return (
      <HStack w="100%">
        <Text ml="2">{name}</Text>
        <Text>
          (N/mm<sup>2</sup>)
        </Text>
      </HStack>
    );
  };

  switch (mode) {
    case LAYOUTMODES.MOBILE:
      return (
        <Box w="100%" pr="5">
          <HStack w="100%" mb="1">
            <FormLabel {...styleProperties} fontSize={resizeElements}>
              {t('steelAdvancedProperties.thickness') + ' (mm)'}
            </FormLabel>
            <FormLabel {...styleProperties} w="100%" fontSize={resizeElements}>
              {addMobileMetrics(t('steelAdvancedProperties.' + property.key))}
            </FormLabel>
          </HStack>
          {property.value.points.map((point, index) => {
            const inputNumberProps = {
              isReadOnly: isOfficialVersion,
              value: (point.y / 1000000).toFixed(1),
              onChange: val => {
                setStrengthFunctions(prevState => {
                  let newState = [...prevState];
                  let currentFunction = newState?.find(
                    k => k.key === property.key
                  );
                  currentFunction.value.points[index].y = val * 1000000;
                  return newState;
                });
              },
              borderLeftColor: borderLeftColor,
              ...colorStyle,
              ...inputWidth,
            };
            return (
              <HStack w="100%" key={point.x} mb="1">
                <Text {...styleProperties} fontSize={resizeElements}>
                  {(point.x * 1000).toFixed(1)}
                </Text>
                <InputNumber
                  styleProperties={styleProperties}
                  resizeElements={resizeElements}
                  props={inputNumberProps}
                />
              </HStack>
            );
          })}
        </Box>
      );
    // Desktop mode
    default:
      return (
        <Flex w="100%" mt="5" pr="1">
          <Box w="100%" mt="5">
            <HStack w="100%">
              <FormLabel {...styleProperties} fontSize={resizeElements}>
                {t('steelAdvancedProperties.thickness')}
              </FormLabel>
              <HStack {...inputWidth} spacing="25px">
                <Slider
                  min="0"
                  max="100"
                  colorScheme="green"
                  isDisabled
                  defaultValue="100"
                  w="90%"
                >
                  {property.value.points.map(point => {
                    const component = (
                      <SliderMark
                        key={point.x}
                        value={currentPositionPoint}
                        mt="-5"
                        ml="-2.5"
                        fontSize="xs"
                      >
                        {(point.x * 1000).toFixed(1)}
                      </SliderMark>
                    );
                    currentPositionPoint =
                      currentPositionPoint + Math.trunc(positionPoint);
                    return component;
                  })}
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                </Slider>
                <Text fontSize="xs" fontWeight="light" as="sup">
                  mm
                </Text>
              </HStack>
            </HStack>
            <HStack w="100%">
              <FormLabel {...styleProperties} fontSize={resizeElements} mt="2">
                {t('steelAdvancedProperties.' + property.key)}
              </FormLabel>
              <HStack
                w="75%"
                spacing="25px"
                borderLeftColor={borderLeftColor}
                {...colorStyle}
              >
                <HStack w="90%" spacing="4">
                  {property.value.points.map(point => {
                    if (point.y === 0) {
                      return null;
                    }
                    return (
                      <Input
                        key={point.x}
                        variant="unstyled"
                        min={0}
                        size={resizeElements}
                        w={nPoints > 2 ? '70%' : '100%'}
                        fontSize={resizeElements}
                        textAlign="center"
                        isReadOnly
                        value={(point.y / 1000000).toFixed(1)}
                      ></Input>
                    );
                  })}
                </HStack>
                <Text w="10%" fontSize="xs" fontWeight="light">
                  N/mm<sup>2</sup>
                </Text>
              </HStack>
            </HStack>
          </Box>
          <IconButton
            bg="transparent"
            icon={<MdModeEdit />}
            disabled={isOfficialVersion}
            ml="2"
            mt="5"
          ></IconButton>
        </Flex>
      );
  }
}

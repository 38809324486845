import {
  Box,
  Button,
  Flex,
  Spacer,
  Icon,
  FormControl,
  FormLabel,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { useThermalProperties } from '../hooks/useThermalProperties';
import InputNumber from './InputNumber';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function ThermalProperties({
  propertyGroups,
  selectedPropertyGroup,
  standard,
  nationalAnnex,
  colorStyle,
  resizeElements,
  styleProperties,
  inputWidth,
  isOfficialVersion,
}) {
  const {
    emissivity,
    setEmissivity,
    customCapacityFunction,
    customConductivityFunction,
    show,
    setShow,
  } = useThermalProperties(
    propertyGroups,
    selectedPropertyGroup,
    standard,
    nationalAnnex
  );
  const { t } = useTranslation('translation');
  const axisColor = useColorModeValue(
    'rgba(118,118,118,0.5)',
    'rgba(219,219,219,0.5)'
  );
  const axisColor2 = useColorModeValue(
    'rgba(118,118,118,0.2)',
    'rgba(219,219,219,0.2)'
  );
  const metricSpecificHeatC = 'C [J/kgK]';
  const metricThermalConductivityK = 'K [W/mK]';
  const labels = [
    20.0, 85.6, 151.1, 216.7, 282.2, 347.8, 413.3, 478.9, 544.4, 609.95, 675.5,
    741.1, 806.6, 872.2, 937.7, 1003.3, 1068.8, 1134.4, 1200.0,
  ];
  const defaultCapacityData = [
    625, 640, 640, 670, 670, 680, 680, 690, 690, 700, 700, 5000, 690, 680, 670,
    625, 625, 625, 625,
  ];
  const defaultConductivityData = [
    53, 50.6, 48.2, 45.8, 43.4, 41, 38.6, 36.2, 33.8, 31.4, 29, 26.6, 26.6,
    26.6, 26.6, 26.6, 26.6, 26.6, 26.6,
  ];

  const createOptions = yTitle => {
    return {
      fill: true,
      responsive: true,
      scales: {
        y: {
          title: {
            display: true,
            text: yTitle,
          },
          min: 0,
          grid: {
            borderColor: axisColor,
            color: axisColor2,
          },
        },
        x: {
          title: {
            display: true,
            text: 'T [ºC]',
          },
          min: 0,
          grid: {
            borderColor: axisColor,
            color: axisColor2,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
    };
  };

  const customCapacityData = {
    datasets: [
      {
        label: 'Default data',
        data: defaultCapacityData,
        tension: 0,
        borderColor: 'rgb(75, 192, 192)',
        pointRadius: 4,
        pointBackgroundColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.3)',
      },
    ],
    labels,
  };

  const customConductivityData = {
    datasets: [
      {
        label: 'Default data',
        data: defaultConductivityData,
        tension: 0,
        borderColor: 'rgb(75, 192, 192)',
        pointRadius: 4,
        pointBackgroundColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.3)',
      },
    ],
    labels,
  };

  const addLinearGraphs = (state, graphsData, graphsOptions) => {
    if (state) {
      const xPoints = [];
      const yPoints = [];
      state.value.points.map(point => {
        xPoints.push(point.x);
        yPoints.push(point.y);
        return { xPoints, yPoints };
      });
      graphsData.datasets[0].label = t('thermalProperties.' + state.key);
      graphsData.datasets[0].data = yPoints;
      graphsData.labels = xPoints;
    }
    return (
      <Box w="100%" mb="5">
        <Box w="99%" justify="center">
          <Line data={graphsData} options={graphsOptions} />
        </Box>
      </Box>
    );
  };

  const emissivityInputProps = {
    isReadOnly: isOfficialVersion,
    value: emissivity?.value,
    step: 0.1,
    onChange: val => {
      setEmissivity(prevState => {
        if (prevState?.value === parseFloat(val)) {
          return prevState;
        }
        return {
          key: prevState.key,
          value: val,
          standard: prevState.standard,
        };
      });
    },
    borderLeftColor:
      emissivity?.standard === 'NotSpecified'
        ? 'grey'
        : emissivity?.standard === nationalAnnex
        ? 'orange.500'
        : 'blue.500',
    ...colorStyle,
    ...inputWidth,
  };

  return (
    <Box w="100%">
      <Button
        fontWeight="semibold"
        mb="3"
        justifySelf="center"
        isFullWidth
        variant="outline"
        justifyContent="flex-start"
        onClick={() => setShow(!show)}
      >
        <Flex w="100%" alignItems="center">
          {t('thermalProperties.thermalProperties')}
          <Spacer />
          <Icon as={show ? FaCaretUp : FaCaretDown} />
        </Flex>
      </Button>
      {show ? (
        <FormControl pl="5" pr="5" mb="5">
          <FormLabel fontSize={resizeElements} fontWeight="light">
            {t('thermalProperties.specificHeatC')}
          </FormLabel>
          {addLinearGraphs(
            customCapacityFunction,
            customCapacityData,
            createOptions(metricSpecificHeatC)
          )}
          <FormLabel fontSize={resizeElements} fontWeight="light">
            {t('thermalProperties.thermalConductivityK')}
          </FormLabel>
          {addLinearGraphs(
            customConductivityFunction,
            customConductivityData,
            createOptions(metricThermalConductivityK)
          )}
          <HStack w="100%">
            <FormLabel
              {...styleProperties}
              fontSize={resizeElements}
              fontWeight="light"
            >
              {t('thermalProperties.emissivity')}
            </FormLabel>
            <InputNumber
              styleProperties={styleProperties}
              resizeElements={resizeElements}
              props={emissivityInputProps}
            />
          </HStack>
        </FormControl>
      ) : null}
    </Box>
  );
}

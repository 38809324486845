import { translate } from './languageService';

export const sortFieldAscending = (data, property, isLocalized) => {
  return data?.sort((a, b) => {
    if (Array.isArray(a[property])) {
      return sortSubArrayAscending(a[property], b[property]);
    }
    if (isLocalized) {
      return sortSimpleFieldAscending(
        a[property]['default'],
        b[property]['default']
      );
    }
    return sortSimpleFieldAscending(a[property], b[property]);
  });
};

export const sortFieldDescending = (data, property, isLocalized) => {
  return data?.sort((a, b) => {
    if (Array.isArray(a[property])) {
      return sortSubArrayDescending(a[property], b[property]);
    }
    if (isLocalized) {
      return sortSimpleFieldDescending(
        a[property]['default'],
        b[property]['default']
      );
    }
    return sortSimpleFieldDescending(a[property], b[property]);
  });
};

const sortSimpleFieldAscending = (a, b) => {
  return a.toLowerCase() > b.toLowerCase()
    ? 1
    : a.toLowerCase() < b.toLowerCase()
    ? -1
    : 0;
};

const sortSimpleFieldDescending = (a, b) => {
  return a.toLowerCase() < b.toLowerCase()
    ? 1
    : a.toLowerCase() > b.toLowerCase()
    ? -1
    : 0;
};

const sortSubArrayAscending = (a, b) => {
  a.sort((subA, subB) => {
    return sortSimpleFieldAscending(subA, subB);
  });
  b.sort((subA, subB) => {
    return sortSimpleFieldAscending(subA, subB);
  });
  return a.map(el => el.toLowerCase()) > b.map(el => el.toLowerCase())
    ? 1
    : a.map(el => el.toLowerCase()) < b.map(el => el.toLowerCase())
    ? -1
    : 0;
};

const sortSubArrayDescending = (a, b) => {
  a.sort((subA, subB) => {
    return sortSimpleFieldDescending(subA, subB);
  });
  b.sort((subA, subB) => {
    return sortSimpleFieldDescending(subA, subB);
  });
  return a.map(el => el.toLowerCase()) < b.map(el => el.toLowerCase())
    ? 1
    : a.map(el => el.toLowerCase()) > b.map(el => el.toLowerCase())
    ? -1
    : 0;
};

export const orderSubcategories = (
  userSettingsSubcategories,
  allSubcategories,
  lang,
  hideEmptySubcategories = false,
  isSettingsPage = false
) => {
  let subcategories = orderSubcategoriesBySettings(
    userSettingsSubcategories,
    allSubcategories,
    isSettingsPage
  );

  let rest = allSubcategories.filter(
    s => !userSettingsSubcategories.some(r => r.id === s.id)
  );

  let restOfSubcategories = rest.map(s => {
    s.hide = false;
    return s;
  });

  if (!isSettingsPage && hideEmptySubcategories) {
    subcategories = subcategories.filter(r => r.count !== 0);
    restOfSubcategories = restOfSubcategories.filter(r => r.count !== 0);
  }

  const restOrdered = orderAlphabeticallyByName(restOfSubcategories, lang);
  restOrdered.map(s => {
    return subcategories.push(s);
  });
  return subcategories;
};

const orderSubcategoriesBySettings = (
  userSettingsSubcategories,
  allSubcategories,
  isSettingsPage
) => {
  let settingsSubcategories = isSettingsPage
    ? userSettingsSubcategories
    : userSettingsSubcategories?.filter(s => s.hide === false);

  return settingsSubcategories.reduce(
    (result, { id, hide }, index, { [index + 1]: next }) => {
      let current = allSubcategories.find(x => x.id === id);
      if (current) {
        current.hide = hide;
        result.push(current);
      }
      if (next) {
        return result;
      }
      return result;
    },
    []
  );
};

const orderAlphabeticallyByName = (array, lang) => {
  return array.sort((a, b) => {
    const aName = translate(a.name, lang);
    const bName = translate(b.name, lang);
    return sortSimpleFieldAscending(aName, bName);
  });
};

export const orderDictionaryByKey = array => {
  return array.sort((a, b) => {
    const keyA = a.key;
    const keyB = b.key;
    return sortSimpleFieldAscending(keyA, keyB);
  });
};

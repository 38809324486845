import { useState, useRef } from 'react';
import { useIdentityContext } from '../contexts/IdentityContext';
import axios from 'axios';
import debounce from 'lodash.debounce';

export const useAxios = toastComponent => {
  const { authHeaders } = useIdentityContext();
  const [dbUpdated, setDbUpdated] = useState(false);
  const [response, setResponse] = useState();

  const showError = errorMessage => {
    toastComponent({
      title: errorMessage,
      status: 'error',
      isClosable: true,
    });
  };

  const debouncedHandler = useRef(
    debounce(
      async (
        payload,
        url,
        errorMessage,
        setCurrentState,
        currentDatabaseState
      ) => {
        return await patchAxios(
          payload,
          url,
          errorMessage,
          setCurrentState,
          currentDatabaseState
        );
      },
      1000
    )
  ).current;

  const patchAxios = async (
    payload,
    url,
    errorMessage,
    setCurrentState,
    currentDatabaseState
  ) => {
    await axios
      .patch(url, payload, { headers: authHeaders })
      .then(response => {
        setDbUpdated(true);
        setResponse(response);
      })
      .catch(response => {
        setCurrentState(JSON.parse(JSON.stringify(currentDatabaseState)));
        showError(errorMessage);
        setResponse(response);
      });
  };

  const postAxios = async (payload, url, errorMessage) => {
    return await axios
      .post(url, payload, { headers: authHeaders })
      .then(response => {
        setResponse(response);
      })
      .catch(response => {
        showError(errorMessage);
        setResponse(response);
      });
  };

  return {
    debouncedHandler,
    patchAxios,
    dbUpdated,
    setDbUpdated,
    postAxios,
    response,
  };
};
